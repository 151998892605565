﻿import Map from './webapp.bingmaps';
import _wa_workspace_ from './webapp.workspace';

var _wa_workorder_ = {
    maps: {}
}

// TODO: Remove map when workorder is closed, use an event for closing workorders/entities

_wa_workorder_.initMap = function (mapDataElement) {

    var $mapDataElement = $(mapDataElement);
    var entityKey = $mapDataElement.data('id').toString();
    var canvasElementId = $mapDataElement.data('map-canvas-element-id');
    
    // TODO: Handle an array of maps for each order
    if (!_wa_workorder_.maps.hasOwnProperty(entityKey)) {
        _wa_workorder_.maps[entityKey] = new Map({ elementId: canvasElementId });
    }

    // Initalizes map if there are no map canvas (first time showing map or after a page reload)
    if ($('#' + canvasElementId + ' canvas').length === 0) {
        _wa_workorder_.maps[entityKey].init(_wa_workorder_); 
    }

    _wa_workorder_.maps[entityKey].removeAllFeatures();

    var id = [$mapDataElement.data('id')];
    var xCoordinate = [$mapDataElement.data('xcoordinate').toString()];
    var yCoordinate = [$mapDataElement.data('ycoordinate').toString()];
    var additionalData = [""];

    _wa_workorder_.maps[entityKey].addFeatures(id, xCoordinate, yCoordinate, additionalData);
    
    _wa_workorder_.maps[entityKey].zoomToExtent();

}



_wa_workorder_.unLoad = function(id) {
    var entityKey = id.toString();
    delete _wa_workorder_.maps[entityKey];
    _wa_workspace_.removeEntity('workorder', id);

}

_wa_workorder_.load = function (workorderIds) {
    if (workorderIds.length === 0) {
        return;
    }

    //selectedWorkorderIds.selectedWorkorderIds
    var unloadedIds = [];
    var newActiveContentPage = 'wa-workorder-' + workorderIds[0] + '-page';

    workorderIds.forEach(function (workorderId) {
        if (!_wa_workspace_.isEntityLoaded('workorder', workorderId))
            unloadedIds.push(workorderId);
    });

    if (unloadedIds.length === 0) {
        _wa_workspace_.changeActiveContentPage(newActiveContentPage);
        return;
    }

    var data = { workorderIds: unloadedIds };
    
    
    var options = {
        url: $('#wa-load-workorder-url').attr('href'),
        data: data,
        loaderTarget: $('div.wa-workorder-assign-page'), // TODO Find the closest content page instead
        loaderMessage: 'Hämtar ordrar...',
        afterSuccess: function () {
            _wa_workspace_.changeActiveContentPage(newActiveContentPage);
        }
    };
    $.fn.postAjax(options);

}

/* Dummy events currently but needed for map init */
_wa_workorder_.onMapSelection = function (selectEvent) {
}

_wa_workorder_.selectAllFeatures = function (workorderIds) {
}

$(document).on('keyup blur change input', '[chars]', function (event) {

   /*  RegEx Examples:
        - email: "0-9a-zA-Z@._\-"
        - numbers only: "0-9"
        - letters only: "a-zA-Z"

        Usage Example:
        <input type="text" name="email" chars="0-9a-zA-Z@._\-" />*/
    var $theFunc = function whatDecimalSeparator() {
        var n = 1.1;
        n = n.toLocaleString().substring(1, 2);
        return n;
    }
    var $elem = $(this),
        value = $elem.val(),
        regReplace;
    var character = "0-9";//$elem.attr('chars');
    character = character + $theFunc();
    var filter = character;
        //filter = $elem.attr('chars');

    regReplace = new RegExp('[^' + filter + ']', 'ig');
    $elem.val(value.replace(regReplace, ''));

});



$(document).on('click', 'button.wa-perform-attest-withwarnings-button', function (event) {
    var $form = $(event.currentTarget).closest('form.wa-attest-withwarnings-workorder-form');
    if (!$form.validate().form()) {
        event.preventDefault();
        return;
    }

    var data = $form.serializeArray();


    var options = {
        url: $form.attr('action'),
        data: $.param(data),
        loaderTarget: $('div.wa-content-page'), // TODO Find the closest dialog instead
        loaderMessage: ".....",
        afterSuccess: function () {
            $form.closest('.ui-dialog-content').dialog('close');
            //var $workorderId = $form.data("workorderid");
            //var newActiveContentPage = 'wa-workorder-' + $workorderId + '-page';
            //_wa_workspace_.changeActiveContentPage(newActiveContentPage);
            /*var $openEntities = $('#wa-loaded-entities-list li');
            var entityAnchor = $openEntities.last().find('a');
            _wa_workspace_.changeActiveContentPage(entityAnchor.data('content-page-container-class'));*/
        }
    };
    $.fn.postAjax(options);



});
$(document).on('click', 'button.wa-perform-fail-attest-button', function (event) {
    var $form = $(event.currentTarget).closest('form.wa-fail-attest-workorder-form');
    if (!$form.validate().form()) {
        event.preventDefault();
        return;
    }

    var data = $form.serializeArray();

    var $createNew = $form.find(".js-workorder-create-new");
    
    var options = {
        url: $form.attr('action'),
        data: $.param(data),
        loaderTarget: $('div.wa-content-page'), // TODO Find the closest dialog instead
        loaderMessage: ".....",
        afterSuccess: function () {
            $form.closest('.ui-dialog-content').dialog('close');
            //var $workorderId = $form.data("workorderid");
            //var newActiveContentPage = 'wa-workorder-' + $workorderId + '-page';
            //_wa_workspace_.changeActiveContentPage(newActiveContentPage);
            var $openEntities = $('#wa-loaded-entities-list li');
            var entityAnchor = $openEntities.last().find('a');
            if ($createNew.val() ==='true')
            _wa_workspace_.changeActiveContentPage(entityAnchor.data('content-page-container-class'));
        }
    };
    $.fn.postAjax(options);



});
$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
    if ($(e.target).is('.wa-workorder-map-tab')) {
        _wa_workorder_.initMap(e.target);
    }
   
});

$(document).on('click', 'span.wa-unload-workorder-button', function (event) {
    var $button = $(event.currentTarget);
    _wa_workorder_.unLoad($button.data('entity-id'));
});


_wa_workorder_.addUploadWorkorderAttachmentHandlers = function registerUploadWorkorderAttachmentFormSubmitHandler() {
    $(document).on('click', 'button.js-upload-workorder-attachment', function (event) {

        event.preventDefault();
        var $form = $(event.currentTarget).closest('form');
        if (!$form.validate().form()) {
            event.preventDefault();
            return;
        }
        var $url = $form.attr('action');
        var data = $form.serializeArray();

        var $contentPage = $form.closest(".wa-content-page").data("workorder-content-page");
        var $containerId = $(this).data('container-id');
        $form.find("#contentPage").val($contentPage);
        $form.find("#containerId").val($containerId);
        var e11 = $form.find("#containerId");
        var e22 = $form.find("#contentPage");

        var e1 = e11.val();
        var e2 = e22.val();
        $form.submit();



    });
}






_wa_workorder_.default_submit = function (event) {
    //event.preventDefault();
    var $form = $(event.currentTarget).closest('form');
    if (!$form.validate().form()) {
        event.preventDefault();
        return;
    }
    var $url = $form.data('url');
    var data = $form.serializeArray();

    var $contentPage = $form.closest(".wa-content-page").data("workorder-content-page");
    var $containerId = $form.data('container-id');
    //var $containerId = $(this).data('container-id');
    data.push({ name: "ContentPage", value: $contentPage })
    data.push({ name: "ContainerId", value: $containerId })



    var loaderMessage = $(event.currentTarget).data('loader-message');

    var options = {
        url: $url,
        data: $.param(data),
        loaderTarget: $('div.wa-content-page'), // TODO Find the closest dialog instead
        loaderMessage: loaderMessage,
    };

    $.fn.postAjax(options);
}


_wa_workorder_.addAllowEditProtocolNo = function () {
    $(document).on('click', '.editProtocolClass', function (e) {
        var $form = $(e.currentTarget).closest("form");
        if ($(this).is(":checked")) {
            $form.find('.js-generate-proto-input').prop('readonly', '');
            $form.find('.js-generate-proto-input').val($form.find('.js-generate-proto-input').data('new-value'));
        }
        else {
            $form.find('.js-generate-proto-input').prop('readonly', 'readonly');
            $form.find('.js-generate-proto-input').data('new-value', $form.find('.js-generate-proto-input').val());
            $form.find('.js-generate-proto-input').val($form.find('.js-generate-proto-input').data('original-value'));
        }

    });//.show();
}

_wa_workorder_.addSaveVerificationDetailHandlers = function registerSaveVerificationDetailFormSubmitHandler() {
    $(document).on('click', 'button.js-save-verification-details', _wa_workorder_.default_submit);
}
_wa_workorder_.addSaveManualErrorHandlingFormSubmitHandler = function registerSaveManualErrorHandlingFormSubmitHandler() {
    $(document).on('click', 'button.js-save-manual-error-handling', _wa_workorder_.default_submit);
}

_wa_workorder_.addOnClickSelectFileToUploadHandler = function () {
    $(document).on('click', '.fileClass', function () {
        $(this).next().find(".fileUpload").click();

    }).show();
}

_wa_workorder_.addOnChangeFileToUploadHandler = function () {
    $(document).on('change', '.fileUpload', function () {
        var text = $(this).val();

        var woid = $(this).data("woid");

        if (text.lastIndexOf("C:\\fakepath\\", 0) === 0) {
            text = text.substring(12, text.length);
        }

        var theTable = $(".UploadAttachmentTable[data-woid='" + woid + "']");

        if (text.length > 0) {

            theTable.find('.fileTextSpan').text("Vald fil: " + text);

            //theTable.find(".saveAttachmentButton").prop("disabled", false);

            theTable.find(".saveAttachementClass").show();

        }
    });
}






/*_wa_workorder_.addVerificationCableValidation = function () {

    $("#wa-main-content-container").on('change', '.js-update-cable-burden-current', onChangeIsMeasuredBurden);
    
    

    function onChangeIsMeasuredBurden(e) {
        var arrayOfMeasured = $(this).closest('.tab-content').find('.js-burden-measured');
        var isMeasured = false;
        arrayOfMeasured.each(function () {
            isMeasured = isMeasured || 'true' == $(this).val() && !$(this).closest("fieldset").find(".js-cable-section-notinuse").is(":checked");
        });
        if (isMeasured) {
            $(this).closest('form').find('.js-display-current-voltage').removeClass('hidden');
        }
        else {
            $(this).closest('form').find('.js-display-current-voltage').addClass('hidden');
        }
    }
    $("#wa-main-content-container").on('change', '.js-update-voltage-cable-ismeasured-voltagedrop', onChangeIsMeasuredVoltageDrop);

    function onChangeIsMeasuredVoltageDrop(e) {
        var arrayOfMeasured = $(this).closest('.tab-content').find('.js-update-voltage-cable-ismeasured-voltagedrop');
        var isMeasuredVoltageDrop = false;
        arrayOfMeasured.each(function () {
            isMeasuredVoltageDrop = isMeasuredVoltageDrop || 'true' == $(this).val();
        });
        if (isMeasuredVoltageDrop) {
            $(this).closest('form').find('.voltage-drop-tab').removeClass('hidden');
            $(this).closest('form').find('.voltage-drop-tab').attr('hidden', false);
        }
        else {
            $(this).closest('form').find('.voltage-drop-tab').addClass('hidden');
            $(this).closest('form').find('.voltage-drop-tab').attr('hidden', true);
        }
    }
    $("#wa-main-content-container").on('ifChanged', '.js-voltagedrop-fuse', onChangeIsMeasuredVoltageDropFuse);

    function onChangeIsMeasuredVoltageDropFuse(e) {
        
        if ($(this).is(":checked")) {
            $(this).closest('form').find('.visibility-voltage-drop-fuse').removeClass('hidden');
            $(this).closest('form').find('.visibility-voltage-drop-fuse').attr('hidden', false);
        }
        else {
            $(this).closest('form').find('.visibility-voltage-drop-fuse').addClass('hidden');
            $(this).closest('form').find('.visibility-voltage-drop-fuse').attr('hidden', true);
        }
    }

    
}*/
_wa_workorder_.addVerificationMeasurementsHandlers = function () {

    $("#wa-main-content-container").on('change', '.js-verification-recalc-main-voltage', onChangePhaseVoltage);
    $("#wa-main-content-container").on('change', '.js-verification-recalc-angles', onRecalcAngles);
    function onRecalcAngles(e) {
        var $table = $(this).closest('table');
        var $u1u2 = parseFloat(preformatFloat($table.find('.js-verification-actualvalues-u1u2').val())) % 360;
        var $u1u3 = parseFloat(preformatFloat($table.closest('table').find('.js-verification-actualvalues-u1u3').val()))%360;
        var $u1i1 = parseFloat(preformatFloat($table.closest('table').find('.js-actualvalues-u1i1').val()))%360;
        var $u2i2 = parseFloat(preformatFloat($table.closest('table').find('.js-actualvalues-u2i2').val()))%360;
        var $u3i3 = parseFloat(preformatFloat($table.closest('table').find('.js-actualvalues-u3i3').val()))%360;
        
        $table.find('.js-verification-actualvalues-u1u2').val($u1u2.toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 4,}));
        $table.find('.js-verification-actualvalues-u1u3').val($u1u3.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4, }));
        $table.find('.js-actualvalues-u1i1').val($u1i1.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4, }));
        $table.find('.js-actualvalues-u2i2').val($u2i2.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4, }));
        $table.find('.js-actualvalues-u3i3').val($u3i3.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4, }));
    }
    
    function onChangePhaseVoltage(e) {
        var $u1 = parseFloat(preformatFloat($(this).closest('table').find('.js-verification-actualvalues-upnl1').val()));
        var $u2 = parseFloat(preformatFloat($(this).closest('table').find('.js-verification-actualvalues-upnl2').val()));
        var $u3 = parseFloat(preformatFloat($(this).closest('table').find('.js-verification-actualvalues-upnl3').val()));
        var $u1u2 = parseFloat(preformatFloat($(this).closest('table').find('.js-verification-actualvalues-u1u2').val()));
        var $u1u3 = parseFloat(preformatFloat($(this).closest('table').find('.js-verification-actualvalues-u1u3').val()));
        UpdateMainVoltage($u1, $u2, $u3, $u1u2, $u1u3, $(this).closest('table'));
    }
    function UpdateMainVoltage(u1, u2, u3, u1u2, u1u3, $table) {
        var UPP1 = Math.sqrt(Math.pow(u1 - u2 * Math.sin(Math.PI / 2 + u1u2 * Math.PI / 180), 2) + Math.pow(u2 * Math.cos(Math.PI / 2 + u1u2 * Math.PI / 180), 2));
        var UPP2 = Math.sqrt(Math.pow(u2 - u3 * Math.sin(Math.PI / 2 + (u1u3 - u1u2) * Math.PI / 180), 2) + Math.pow(u3 * Math.cos(Math.PI / 2 + (u1u3 - u1u2) * Math.PI / 180), 2));
        var UPP3 = Math.sqrt(Math.pow(u3 - u1 * Math.sin(Math.PI / 2 + 2 * Math.PI - u1u3 * Math.PI / 180), 2) + Math.pow(u1 * Math.cos(Math.PI / 2 + 2 * Math.PI - u1u3 * Math.PI / 180), 2));

        $table.find('.js-verification-actualvalues-upp1').val(UPP1.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4, }));
        $table.find('.js-verification-actualvalues-upp2').val(UPP2.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4, }));
        $table.find('.js-verification-actualvalues-upp3').val(UPP3.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4, }));

    }

    
    function preformatFloat(float) {
        if (!float) {
            return '';
        };

        //Index of first comma
        const posC = float.indexOf(',');

        if (posC === -1) {
            //No commas found, treat as float
            return float;
        };

        //Index of first full stop
        const posFS = float.indexOf('.');

        if (posFS === -1) {
            //Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
            return float.replace(/\,/g, '.');
        };

        //Uses both commas and full stops - ensure correct order and remove 1000s separators
        return ((posC < posFS) ? (float.replace(/\,/g, '')) : (float.replace(/\./g, '').replace(',', '.')));
    }




}

_wa_workorder_.addVerificationMeterNormChangeHandlers = function () {

    $("#wa-main-content-container").on('change', '.js-verification-device-norm-type', onChangeMeterNorm);

    function onChangeMeterNorm(e) {
        
        var $normId = $(this).val();
        var $containerId = $(this).data('container-id');
        var $parentForm = $(e.target).closest('form');
        updateClassList($parentForm, $normId, $containerId);
    }
    function updateClassList($parentForm, normId,containerId) {
        //toggleModelMissing($parentForm, true);
        var $normSelect = $parentForm.find(".js-verification-device-norm-type");
        var url = $normSelect.data("url");

        var contentPage = $parentForm.closest(".wa-content-page").data("workorder-content-page");
        var data = $parentForm.serializeArray();
        data.push({ name: "ContentPage", value: contentPage })
        data.push({ name: "ContainerId", value: containerId })
        var options = {
            url: url,
            data: $.param(data),
            loaderTarget: contentPage,
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);
        
    }
}


_wa_workorder_.addVerificationChangeTemperatureIntervalHandler = function () {

    $("#wa-main-content-container").on('change', '.js-operating-conditions-temperature-interval', onChangeTemperatureInterval);

    function onChangeTemperatureInterval(e) {

        var $temperatureIntervalId = $(this).val();
        var $parentForm = $(e.target).closest('form');
        var $containerId = $parentForm.data('inner-container-id');
        var $temperatureSelect = $(this);//$parentForm.find(".js-verification-device-norm-type");
        var url = $temperatureSelect.data("url");

        var $contentPage = $parentForm.closest(".wa-content-page").data("workorder-content-page");
        var data = { "TemperatureIntervalId": $temperatureIntervalId, "contentPage": $contentPage, containerId:$containerId };

        var options = {
            url: url,
            data: $.param(data),
            loaderTarget: $contentPage,
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    }
}

_wa_workorder_.addEditDeviceDialogHandlers = function () {
    
    $("#wa-main-content-container").on('change', '.wa-edit-device-manufacturer-dropdown', onChangeManufacturer);
    $("#wa-main-content-container").on('change', '.wa-edit-device-model-dropdown', onChangeModel);


    function toggleModelMissing($parentForm, missing) {
        $parentForm.find(".wa-edit-device-model").prop("readOnly", !missing);
        if (missing)
            $parentForm.find(".wa-edit-device-model-dropdown").selectedIndex = -1;
    }


    function toggleManufacturerMissing($parentForm, missing) {
        $parentForm.find(".wa-edit-device-manufacturer").prop("readOnly", !missing);
        toggleModelMissing($parentForm, missing);
    }


    function onChangeManufacturer(e) {
        var $manufacturerId = $(this).val();
        var missing = $manufacturerId == -1;
        var $parentForm = $(e.target).closest('form');
        if (!missing)
            $parentForm.find('.wa-edit-device-manufacturer').val($parentForm.find(".wa-edit-device-manufacturer-dropdown").find(':selected').text());
        else
            $parentForm.find('.wa-edit-device-manufacturer').val('');

        toggleManufacturerMissing($parentForm, missing);
        updateModelList($parentForm, $manufacturerId);
    }
    function onChangeModel(e) {
         var modelId = $(this).val();
        var missing = modelId == -1;
        var $parentForm = $(e.target).closest('form');
        if (!missing)
            $parentForm.find('.wa-edit-device-model').val($parentForm.find('.wa-edit-device-model-dropdown').find(':selected').text());
        else
            $parentForm.find('.wa-edit-device-model').val('');

          toggleModelMissing($parentForm, missing);
          

    }
    function clearModelList() {
        $("#_currentDialog form .js-editdevice-modelselect").children().remove().end();
    }
    function updateModelList($parentForm, manufacturerId) {
        $parentForm.find(".wa-edit-device-model").val('');
        //toggleModelMissing($parentForm, true);
        var $manufacturerSelect = $parentForm.find(".wa-edit-device-manufacturer-dropdown");
        var deviceTypeId = $parentForm.data("device-type");
        var deviceId = $parentForm.data("device-id");
        var url = $manufacturerSelect.data("url");

        var contentPage = $parentForm.closest(".wa-content-page").find("#accordion").data("workorder-content-page");

        var data = {
            ManufacturerId: manufacturerId,
            DeviceTypeId: deviceTypeId,
            ContentPage: contentPage,
            DeviceId: deviceId
        };
        var options = {
            url: url,
            data: $.param(data),
            loaderTarget: contentPage,
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);
        toggleModelMissing($parentForm, true);
    }
}

_wa_workorder_.addEditDeviceDialogHandlers();

_wa_workorder_.addVerificationMeterNormChangeHandlers();

_wa_workorder_.addAllowEditProtocolNo();


_wa_workorder_.addSaveVerificationDetailHandlers();

_wa_workorder_.addSaveManualErrorHandlingFormSubmitHandler();

_wa_workorder_.addVerificationChangeTemperatureIntervalHandler();

_wa_workorder_.addUploadWorkorderAttachmentHandlers();
_wa_workorder_.addVerificationMeasurementsHandlers();
//_wa_workorder_.addVerificationCableValidation();
//_wa_workorder_.addOnClickSelectFileToUploadHandler();

//_wa_workorder_.addOnChangeFileToUploadHandler();


export default _wa_workorder_;
