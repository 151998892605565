﻿import _wa_datatables_ from "./webapp.datatables";
import _wa_toast_ from "./webapp.toast";

var _wa_project_ = {

}

$("#wa-main-content-container").on('init.dt', "#wa-user-search-result-container", function (e) {
    var $table = $(e.target);
    var $dataTable = $table.DataTable();

    //_wa_project_.addEditButton($table, $dataTable);
    var editButtonOptions = {
        url: $('#wa-user-editor-dialog-url').attr('href'),
        loaderTargetSelector: 'div.wa-user-page',
        idColumnSelector: 'userId:name',
        contentInstruction: { replaceInnerFor: '#wa-user-search-result-container' },
        readonly: $('#wa-has-manage-users-authorization').length === 0
    };
    _wa_datatables_.addEditButton($table, $dataTable, editButtonOptions);

    /*
    var refreshButtonOptions = {
        idColumnSelector: 'workorderId:name',
        tableContainerSelector: '#wa-workorder-assign-search-result-container',
        refreshActionUrl: $('#wa-search-assign-workorder-url').attr('href'),
        loaderTargetSelector: 'div.wa-workorder-assign-page'
    };

    _wa_datatables_.addRefreshButton($table, $dataTable, refreshButtonOptions);
    */
});