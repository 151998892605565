﻿import $ from 'jquery';
import _wa_localization_ from './webapp.localization.js';
import _datatables_sv_ from '../json/datatables/sv.json';
import _datatables_en_ from '../json/datatables/en.json';
import 'datatables.nett';
import 'datatables.net-bss';
//import 'datatables.net-rowreorder';
//import 'datatables.net-rowreorder-bs';
//import 'datatables.net-rowreorder-dt';
//import 'datatables.net-scroller';
import 'datatables.net-select.js';
import 'datatables.net-select-b4.js';
import 'datatables.net-buttonss';
import 'datatables.net-buttons-bss';
import 'datatables.net-buttons_buttons.html5.js';
import _wa_toast_ from "./webapp.toast";

//require('datatables.net-buttons-bs/css/buttons.bootstrap.css')

var _wa_datatables_ = function () {

}

// TODO: In the future possibly add language as part of layout html
_wa_datatables_.getLanguage = function () {
    if (_wa_localization_.getCurrentLanguage() === 'sv')
        return _datatables_sv_;
    else 
        return _datatables_en_;
}

_wa_datatables_.addToolbarItem = function (table, button) {
    var $table = $(table);

    var $toolbarContainer = $table.closest('.dataTables_wrapper').find('div.wa-table-toolbar');
    $(button).appendTo($toolbarContainer);
}

_wa_datatables_.addFooterItem = function (table, button) {
    var $table = $(table);

    var $footerContainer = $table.closest('.dataTables_wrapper').find('div.wa-table-footer-items-container');
    $(button).appendTo($footerContainer).addClass('float-right ml-2 mr-0');
}

_wa_datatables_.addSelectAllButton = function ($table, $dataTable) {
    var $selectAllButton = $('#wa-button-templates .wa-select-all-button').clone();

    $selectAllButton.on('click', function () {
        $dataTable.rows({ selected: false }).select();
    });

    _wa_datatables_.addToolbarItem($table, $selectAllButton);
}


_wa_datatables_.addLocalButton = function ($table, $dataTable, options, buttonTemplate) {
    var buttonSelector = '#wa-button-templates ' + buttonTemplate;

    if (options.readonly === true) {
        buttonSelector = '#wa-button-templates .wa-view-button';
    }

    var $button = $(buttonSelector).clone();

    $button.on('click', function () {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        event.stopPropagation();
        event.preventDefault();

        var data = _wa_datatables_.getSelectionData($table, $dataTable, options);

        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }

        options.DoProcess();
    });

    _wa_datatables_.addFooterItem($table, $button);
}

_wa_datatables_.addLocalDeleteButton = function ($table, $dataTable, deleteOptions) {
    var buttonSelector = '#wa-button-templates .wa-delete-button';

    if (deleteOptions.readonly === true) {
        return;
    }

    var $deleteButton = $(buttonSelector).clone();

    $deleteButton.on('click', function () {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        event.stopPropagation();
        event.preventDefault();

        var data = _wa_datatables_.getSelectionData($table, $dataTable, deleteOptions);

        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }

        deleteOptions.Delete();
    });

    _wa_datatables_.addFooterItem($table, $deleteButton);
}

$.fn.addLocalDeleteButton = _wa_datatables_.addLocalDeleteButton;




_wa_datatables_.addLocalEditButton = function ($table, $dataTable, editOptions) {
    var buttonSelector = '#wa-button-templates .wa-edit-local-button';

    if (editOptions.readonly === true) {
        buttonSelector = '#wa-button-templates .wa-view-button';
    }

    var $editButton = $(buttonSelector).clone();


    
/*    $table.closest('.dataTables_wrapper').find('div.wa-table-footer-items-container').find('.wa-edit-local-button').on('click', function (event) {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        event.stopPropagation();
        event.preventDefault();

        var data = _wa_datatables_.getSelectionData($table, $dataTable, editOptions);

        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }

        editOptions.Edit();
    });*/
    $editButton.on('click', function () {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        event.stopPropagation();
        event.preventDefault();

        var data = _wa_datatables_.getSelectionData($table, $dataTable, editOptions);

        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }

        editOptions.Edit();
    });

        _wa_datatables_.addFooterItem($table, $editButton);
}

$.fn.addLocalEditButton = _wa_datatables_.addLocalEditButton;


/*
         let protocolExport = $table.data('protocol-export-enabled') === 'True';
        if (protocolExport) {
            var $dataTable = $table.DataTable({ "bRetrieve": true });
            var $loaderTargetSelector = $table.closest(".wa-content-page");
            var data = {
                IgnoreResults: 'True'
            };


            var editButtonOptions = {
                url: $table.data('protocol-url'),
*
*/
_wa_datatables_.addExportButton = function ($buttonClass, $table, $dataTable, editOptions) {

//    buttons.push({ extend: 'csv', text: '<i class="fa fa-table"></i>' });

    
    
    var $scheduleSubmit = function(e) {
        var $target = $(e.target);
        var onceHandle = window.setTimeout(function () { $function($target);} );
    };

    var $function = function ($target) {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        var data = _wa_datatables_.getSelectionData($table, $dataTable, editOptions);
                if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }

        var form = $('<form></form>').attr('action', editOptions.url).attr('method', 'post').attr('target', '_blank');

        $.each(data.selectedIds, function (idx, val) {
            form.append($("<input></input>").attr('type', 'hidden').attr('name', 'SelectListIds[' + idx + ']').attr('value', val));
        });

        form.addClass('no-def-submit');
        /*form.on('submit', function (e) {
            e.stopImmediatePropagation();
        });*/
        //send request
        form.appendTo('body').submit();//.remove();
//        $('body').remove(form);
        //  form.remove();

    };


    $dataTable.button().add(0, {
        action: $scheduleSubmit,
        text: '<i class="fas fa-file-download text-success"></i>',
        titleAttr: 'Protokoll',
        className:'btn-default dt-export-button'
    });
    
    var buttonSelector = '#wa-button-templates ' + $buttonClass;
    if (editOptions.readonly === true) {
        buttonSelector = '#wa-button-templates .wa-view-button';
    }
    var $editButton = $(buttonSelector).clone();

    $editButton.on('click', $scheduleSubmit);
    _wa_datatables_.addFooterItem($table, $editButton);

    //        text: '<i class="glyphicon glyphicon-file"></i>',
    //text: '<div class="<label>HAJJ</label> <i class="fa-cloud-download"></i>',
//        className: 'btn btn-default btn-success'

}
$.fn.addExportButton = _wa_datatables_.addExportButton;
/*
_wa_datatables_.addExportButton = function ($buttonClass, $table, $dataTable, editOptions) {
    var buttonSelector = '#wa-button-templates ' + $buttonClass;

    if (editOptions.readonly === true) {
        buttonSelector = '#wa-button-templates .wa-view-button';
    }

    var $editButton = $(buttonSelector).clone();


    $editButton.on('click', function (e) {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        var data = _wa_datatables_.getSelectionData($table, $dataTable, editOptions);
        var $url = $(e.target).closest('div.wa-workorder-search-page').data("protocol-url");
        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }
        var form = $('<form></form>').attr('action', $url).attr('method', 'post').attr('target', '_blank');

        $.each(data.selectedIds, function (idx, val) {
            form.append($("<input></input>").attr('type', 'hidden').attr('name', 'SelectListIds[' + idx + ']').attr('value', val));
        });
        
        form.addClass('no-def-submit');
        //send request
        form.appendTo('body').submit();
        $('body').remove(form);

    });
    _wa_datatables_.addFooterItem($table, $editButton);
}*/


_wa_datatables_.addCustomEditButton = function ($buttonClass, $table, $dataTable, editOptions) {
    var buttonSelector = '#wa-button-templates ' + $buttonClass;

    if (editOptions.readonly === true) {
        buttonSelector = '#wa-button-templates .wa-view-button';
    }

    var $editButton = $(buttonSelector).clone();


    $editButton.on('click', function () {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        var data = _wa_datatables_.getSelectionData($table, $dataTable, editOptions);

        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }
        if (data.selectedIds.length > 1) {
            _wa_toast_.toastInfo("Det går bara att uppdatera en post");
            return;
        }

        data.IsCreate = false;

        var options = {
            url: editOptions.url,
            data: $.param(data),
            loaderTarget: $(editOptions.loaderTargetSelector),
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);
        
    });
    _wa_datatables_.addFooterItem($table, $editButton);

    /*$table.closest('.dataTables_wrapper').find('div.wa-table-footer-items-container').find('.wa-edit-button').on('click', function () {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        var data = _wa_datatables_.getSelectionData($table, $dataTable, editOptions);

        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }

        data.IsCreate = false;

        var options = {
            url: editOptions.url,
            data: $.param(data),
            loaderTarget: $(editOptions.loaderTargetSelector),
            loaderMessage: "Laddar dialog..."

        };

        $.fn.postAjax(options);
    });*/

}


_wa_datatables_.addCustomDeleteButton = function ($buttonClass, $table, $dataTable, deleteOptions) {
    var buttonSelector = '#wa-button-templates ' + $buttonClass;

    if (deleteOptions.readonly === true) {
        return;
    }

    var $deleteButton = $(buttonSelector).clone();


    $deleteButton.on('click', function () {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        var data = _wa_datatables_.getSelectionData($table, $dataTable, deleteOptions);

        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }

        data.IsCreate = false;

        var options = {
            url: deleteOptions.url,
            data: $.param(data),
            loaderTarget: $(deleteOptions.loaderTargetSelector),
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });
    _wa_datatables_.addFooterItem($table, $deleteButton);

    /*$table.closest('.dataTables_wrapper').find('div.wa-table-footer-items-container').find('.wa-edit-button').on('click', function () {
        // var data = $.extend({isCreate:false},editOptions.data,_wa_datatables_.getSelectionData($table, $dataTable, editOptions));
        var data = _wa_datatables_.getSelectionData($table, $dataTable, editOptions);

        if (data.selectedIds.length === 0) {
            _wa_toast_.toastInfo("Ingen rad markerad i tabell");
            return;
        }

        data.IsCreate = false;

        var options = {
            url: editOptions.url,
            data: $.param(data),
            loaderTarget: $(editOptions.loaderTargetSelector),
            loaderMessage: "Laddar dialog..."

        };

        $.fn.postAjax(options);
    });*/

}
_wa_datatables_.addDeleteButton = function ($table, $dataTable, deleteOptions) {
    _wa_datatables_.addCustomDeleteButton('.wa-delete-button', $table, $dataTable, deleteOptions);
}
$.fn.addDeleteButton = _wa_datatables_.addCustomDeleteButton;
$.fn.addDeleteButton = _wa_datatables_.addDeleteButton;




_wa_datatables_.addEditButton = function ($table, $dataTable, editOptions) {
    _wa_datatables_.addCustomEditButton('.wa-edit-button', $table, $dataTable, editOptions);
}
$.fn.addEditButton = _wa_datatables_.addCustomEditButton;
$.fn.addEditButton = _wa_datatables_.addEditButton;


_wa_datatables_.getSelectionData = function ($table, $dataTable, options) {
    var selectedRowIndexes = $dataTable.rows({ selected: true }).indexes();
    var selectedIds = $dataTable.cells(selectedRowIndexes, options.idColumnSelector).data().toArray();

    var unselectedRowIndexes = $dataTable.rows({ selected: false }).indexes();
    var unselectedIds = $dataTable.cells(unselectedRowIndexes, options.idColumnSelector).data().toArray();
    var data = {};
    if (options.data === undefined) {
        data = {
            selectedIds: selectedIds,
            unselectedIds: unselectedIds,
            contentInstruction: options.contentInstruction,
            contentPage: $contentPage,
            containerId: $containerId
        };
        return data;
    }
    else
    {
        options.data.selectedIds = selectedIds;
        options.data.unselectedIds = unselectedIds;
        options.data.contentInstruction = options.contentInstruction;
        var $contentPage = "";
        var $containerId = "";
        if (options.data.contentPage !== undefined)
            $contentPage = options.data.contentPage;
        if (options.data.containerId !== undefined)
            $containerId = options.data.containerId;

        return options.data;
    }
    
    
}


_wa_datatables_.addRefreshButton = function ($table, $dataTable, options) {
    var $refreshButton = $('#wa-button-templates .wa-refresh-button').clone();

    $refreshButton.on('click', function () {
        // TODO: Remember selected ids

        // Possibly put the ids in options to reselect them later?
        // var selectedRowIndexes = $dataTable.rows({ selected: true }).indexes();
        // var selectedWorkorderIds = $dataTable.cells(selectedRowIndexes, 'workorderId:name').data(); // Need this to reselect

        var allEntityIds = $dataTable.column(options.idColumnSelector).data().toArray();
        var data = {
            EntityIds: allEntityIds,
            ContentInstruction: { ReplaceInnerFor: options.tableContainerSelector }
        };

        var ajaxOptions = {
            url: options.refreshActionUrl,
            data: $.param(data),
            loaderTarget: $(options.loaderTargetSelector),
            loaderMessage: "Uppdaterar tabell med senaste data..."
        };
        $.fn.postAjax(ajaxOptions);

    });

    _wa_datatables_.addToolbarItem($table, $refreshButton);

}

$.fn.dataTableFeatures = function (options) {

}

// Ugly hack that fixes table columns header by redrawing and restores scroll position after redrawing
$.fn.webAppDataTableRedraw = function (options) {
    $('div.dataTables_scrollBody .wa-data-table', this).each(function (index) {
        var $dataTable = $(this).DataTable();
        var $scrollDiv = this.closest('div.dataTables_scrollBody');
        var scrollPos = $scrollDiv.scrollTop;
        $dataTable.draw();
        $scrollDiv.scrollTop = scrollPos;
    });
}

$.fn.webAppQueueTableRedraw = function(delayBeforeUpdate) {
    if (!delayBeforeUpdate)
        delayBeforeUpdate = 300;

    var $this = $(this);

    setTimeout(function () {
        $this.webAppDataTableRedraw();
    }, delayBeforeUpdate);
}


$.fn.webAppDataTable = function (options) {

    $('.wa-data-table', this).each(function (index) {
        var $this = $(this);

        let verticalScrollbarSize = $this.data('vertical-scrollbar-size');

        let searchEnabled = $this.data('searching-enabled') === 'True';
        let searchDom = searchEnabled ? "<'form-group'f>" : "";

        let selectOptions = $this.data('selection-enabled') === 'True';
        let rowReorder = $this.data('rowreorder') === 'True';
        
        let ordering = $this.data('sorting-enabled') === 'True';
        if (selectOptions) {
            let selectStyle = $this.data('multi-selection-allowed') === 'True' ? 'os' : 'single';
            selectOptions = {
                style: selectStyle,
                selector: 'td:not(".unselectable")'
                }; 

        }

        var buttons = [];
/*        if ($this.data('csv-export-enabled') === 'True') {
            buttons.push({ extend: 'csv', text: '<i class="fa fa-table"></i>' });
        }
        */
        var exportopt = [];
        var $allIds = $this.find('thead th');
        var tehIndex = 0;
        $this.find('thead th:not(.noExport)').each(function (index, element) {
            tehIndex = $allIds.index(element);
            exportopt.push(tehIndex);
        });

/*            if ($this.data('csv-export-enabled') === 'True') {
                buttons.push({
                    extend: 'excel',
                    text: '<i class="fa fa-table"></i>'
                });
            }*/
        if ($this.data('csv-export-enabled') === 'True') {
            buttons.push({
                extend: 'csv',
                text: '<i class="fa fa-table"></i>',
                charset: 'utf-8',
                extension: '.csv',
                fieldSeparator: ';',
                fieldBoundary: '',
                filename: 'export',
                bom: true,
                className:'btn-default',
                exportOptions: {
                    columns: exportopt
                }
            });

        }
        //columns: ["thead th:not(.noExport)", "thead th.forceExport"] 
        //columns: "thead th:not(.noExport)"
        var domLayout = ""; // DataTables use special formatting for layout

        // Table Header
        if ($this.data('header-rendering-enabled') === 'True') {
            domLayout += "<'row'<'col-md-6 wa-table-toolbar'><'col-md-6 d-flex justify-content-end'<'row'" + searchDom + "<'form-group table-export-button-container'B>>>>";
        }

        // Table Data
        domLayout += "<'row'<'col-sm-12'tr>>";

        // Table Footer
        if ($this.data('footer-rendering-enabled') === 'True') {
            domLayout += "<'row wa-table-footer'<'col-md-5 text-left'i><'col-md-7 wa-table-footer-items-container'>>"
        }
        // Pagination (not enabled, scroll is used instead)  <'col-md-3'p>

        $this.DataTable({
            'paging': false,
            'order': [],
            'lengthChange': false,
            'searching': searchEnabled,
            'ordering': ordering,
           'rowReorder':rowReorder,
            'info': true,
            'autoWidth': true,
            'deferRender': true,
            'scrollY': verticalScrollbarSize, //  50vh Appears to mess up header columns!?!?!?! WTF
            //'scrollX': '150vw',
            'scrollX': true,
            'scrollCollapse': true,
            'scroller': true, //{ displayBuffer: 50000 }, // Hacked for scrollTop bugfix  //true,
            'fixedColumns': false,
            //"bInfo": false,
            //"bFooter": false,
            'select': selectOptions,
            "language": _wa_datatables_.getLanguage(),
            "oSearch": { "bSmart": false, "bRegex": true },
            dom: domLayout,
            buttons: buttons,
            "initComplete": function (settings, json) {
                // $table.trigger("initialized.dt.wa");
            }

            // Ugly hack below, seems like some code that runs after this script section readjusts the gui and messes up the table headers
            /*
            setTimeout(function () {
                $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust().draw();
            }, 100);
            */

        }); // end datatable
        
    }); // end each
}

/* Updates table headers on a newly display bootstrap tab */
$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {

    var redrawContainerSelector = $(e.target).attr('href');
    $(redrawContainerSelector).webAppDataTableRedraw(); 

});
/* Updates table headers on a newly display bootstrap tab */
$(document.body).on('shown.bs.collapse', function (e) {

    var redrawContainerSelector = $(e.target);
    $(redrawContainerSelector).webAppDataTableRedraw();

});

export default _wa_datatables_;