﻿import _wa_datatables_ from "./webapp.datatables";
import _wa_toast_ from "./webapp.toast";

var _wa_workteam_ = {

}

$("#wa-main-content-container").on('init.dt', "#wa-workteam-search-result-container", function (e) {
    var $table = $(e.target);
    var $dataTable = $table.DataTable();

    //_wa_project_.addEditButton($table, $dataTable);
    var editButtonOptions = {
        url: $('#wa-workteam-editor-dialog-url').attr('href'),
        loaderTargetSelector: 'div.wa-workteam-page',
        idColumnSelector: 'workTeamId:name',
        contentInstruction: { replaceInnerFor: '#wa-workteam-search-result-container' },
        readonly: $('#wa-has-manage-workteams-authorization').length === 0
    };
    _wa_datatables_.addEditButton($table, $dataTable, editButtonOptions);

    /*
    var refreshButtonOptions = {
        idColumnSelector: 'workorderId:name',
        tableContainerSelector: '#wa-workorder-assign-search-result-container',
        refreshActionUrl: $('#wa-search-assign-workorder-url').attr('href'),
        loaderTargetSelector: 'div.wa-workorder-assign-page'
    };

    _wa_datatables_.addRefreshButton($table, $dataTable, refreshButtonOptions);
    */
});





//$(".wa-project-checklist-table", this).on('init.dt', function (e) {


//$('.wa-project-dialog-action-button', this).on('click', function (event) {
$("#wa-main-content-container").on('click', '.wa-workteam-dialog-action-button', function (event) {
    event.preventDefault();

    var $form = $(event.target).closest('form');
    if (!$form.validate().form()) {
        event.preventDefault();
        return;
    }
    var data = $form.serializeArray();

    var $dataTable = $form.find('.wa-workteam-user-table').DataTable({ "bRetrieve": true });
    var rowIndexes = $dataTable.rows().indexes();
    var $UserIds = $dataTable.cells(rowIndexes, 'UserId:name').data().toArray();
    var $Ids = $dataTable.cells(rowIndexes, 'Id:name').data().toArray();

    if ($(event.currentTarget).data('content-page') != null)
        data.push({ name: 'contentPage', value: $(event.currentTarget).data('content-page') });
    //            data = $.extend({}, data, test);

    $.each($UserIds, function (idx, val) {
        data.push({
            name: 'WorkTeamUserList.Rows[' + idx + '].UserId', value: val
        });
    });
    $.each($Ids, function (idx, val) {
        data.push({
            name: 'WorkTeamUserList.Rows[' + idx + '].Id', value: val
        });
    });


    //  data = $.extend({}, data, test);
    var loaderMessage = $(event.currentTarget).data('loader-message');

    var options = {
        url: $form.attr('action'),
        data: $.param(data),
        loaderTarget: $('div.wa-content-page'), // TODO Find the closest dialog instead
        loaderMessage: loaderMessage,
        afterSuccess: function () {
            $form.closest('.ui-dialog-content').dialog('close');
        }
    };
    $.fn.postAjax(options);
});






