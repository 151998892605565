﻿'use strict';
///<reference path="../node_modules/@types/node/index.d.ts" />
import './webapp.styles';
import './webapp.dashboard.js';
import $ from 'jquery';
import 'chart.js/dist/chart.min.js';
import chartdatalabels from 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import _wa_workspace_ from './webapp.workspace.js';
import './webapp.workorder';
import './webapp.assign';
import './webapp.project';
import './webapp.typedata';
import './webapp.workteam';
import './webapp.user';
import './webapp.equipment';
import './webapp.fileimport';
import './webapp.alertsearch';
import './webapp.customercontact';
import './webapp.plan';

var _wa_startup_ = { ChartDataLabels:chartdatalabels}
//var _wa_startup_ = {}

// JQuery on load startup
$(function () {
    $('body').formExtensions();
    // TODO: Found the startup content page (the only)

    var isLoginPage = $(location).attr('href').indexOf('Account/Login') !== -1;

    if (!isLoginPage) {
        // Find startpage
        var classes = $('.wa-content-page').first().attr("class").split(" ");
        var startPage = '';
        $(classes).each(function (index, cl) {
            if (cl.indexOf('-page') !== -1)
                startPage = cl;
        });

        _wa_workspace_.changeActiveContentPage(startPage);
    }
});

export default _wa_startup_;
