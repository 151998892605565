﻿import _wa_toast_ from "./webapp.toast";

var _wa_customer_contact_ = {

}

_wa_customer_contact_.enableCustomerContactRegistration = function ($form) {
    $form.find('.wa-customer-contact-result-type-dropdown').prop('disabled', false);
    $form.find('.wa-customer-contact-results').prop('hidden', false);
}

_wa_customer_contact_.disableCustomerContactRegistration = function ($form) {
    $form.find('.wa-customer-contact-result-type-dropdown').prop('disabled', true);
    $form.find('.wa-customer-contact-results').prop('hidden', true);
    $form.find('.wa-customer-contact-unreachable-result').prop('hidden', true);
    $form.find('.wa-customer-contact-results .wa-has-result').val(false);
}

_wa_customer_contact_.enableCallAttemptRegistration = function ($form) {
    $form.find('.wa-customer-contact-results').prop('hidden', true);
    $form.find('.wa-customer-contact-unreachable-result').prop('hidden', false);
    $form.find('.wa-customer-contact-unreachable-result .wa-has-result').val(true);
}

_wa_customer_contact_.disableCallAttemptRegistration = function ($form) {
    // TODO:
}

$("#wa-main-content-container").on('click', '.wa-remove-customer-contact-result', function (e) {
    var $closeButton = $(e.currentTarget);

    $closeButton.closest('fieldset').prop('hidden', true);
    $closeButton.closest('fieldset').find('.wa-has-result').val(false);

    $closeButton.closest('form').find('.wa-customer-contact-result-type-dropdown').val('').trigger('change');
    
});

// Shows the result container for the result type picked from dropdown and changes its hidden "has-result" value to true
$("#wa-main-content-container").on('change', '.wa-customer-contact-result-type-dropdown', function (e) {
    var $resultTypeDropDown = $(e.currentTarget);
    var $form = $resultTypeDropDown.closest('form');

    if ($resultTypeDropDown.val().trim().length == 0)
        return;

    var resultTypeClassSelector = '.wa-result-type-' + $resultTypeDropDown.val();
    
    $form.find(resultTypeClassSelector).prop('hidden', false);
    $form.find(resultTypeClassSelector).find('.wa-has-result').val(true);

    
});


$("#wa-main-content-container").on('change', '.wa-customer-contact-direction-and-type-dropdown', function (e) {
    var $typeDirectionDropDown = $(e.currentTarget);

    var directionId = +$typeDirectionDropDown.val().split(',')[0];
    var contactTypeId = +$typeDirectionDropDown.val().split(',')[1];

    var $form = $typeDirectionDropDown.closest('form');

    _wa_customer_contact_.disableCustomerContactRegistration($form);
    _wa_customer_contact_.disableCallAttemptRegistration($form);

    var isInbound = directionId === $('#WorkorderEventCustomerContactDirectionTypeEnum').data('inbound');
    var isOutbound = directionId === $('#WorkorderEventCustomerContactDirectionTypeEnum').data('outbound');
    var isCall = contactTypeId === $('#WorkorderEventCustomerContactTypeEnum').data('call');
    var isCallAttempt = contactTypeId === $('#WorkorderEventCustomerContactTypeEnum').data('call-attempt');

    if (isInbound) {
        _wa_customer_contact_.enableCustomerContactRegistration($form);
    } else if (isOutbound && isCall) {
        _wa_customer_contact_.enableCustomerContactRegistration($form);
    } else if (isOutbound && isCallAttempt) {
        _wa_customer_contact_.enableCallAttemptRegistration($form);
    }


});


