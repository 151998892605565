﻿//import 'bootstrap/dist/css/bootstrap.css';
import 'jquery-ui-dist/jquery-ui.css';
import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
import 'admin-lte/plugins/ion-rangeslider/css/ion.rangeSlider.css';
import 'select2/dist/css/select2.css';
import 'select2-theme-bootstrap4/dist/select2-bootstrap.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'daterangepicker/daterangepicker.css';
//import 'datatables.net-bs/css/dataTables.bootstrap.css';
import 'datatables.bootstrap.css';
//import 'datatables.net-scroller-bs/css/scroller.bootstrap.css';
import 'datatables.net-select-bs4.css';
import 'toastrcss'
import 'admin-lte/dist/css/AdminLTE.css';
//import 'admin-lte/dist/css/skins/skin-blue-light.css';
import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import 'ol/ol.css';
import 'jquery-loadingModal/css/jquery.loadingModal.css'
//import 'bs4-utilities-for-bs3/dist/css/bs4-utilities-for-bs3.css'
import '../css/webapp.adminlte.css';
import '../css/webapp.map.css';
import '../css/webapp.datatables.css';
import '../css/webapp.loader.css';
import '../css/webapp.jquery-ui.css'
import '../css/webapp.icons.css'
import '../css/webapp.bootstrap.css'
import '../css/site.css';

// These are bundled here for various reasons, mainly to get a good overview of load order since certain styles may override others.
// As of now, they are injected by webpack, but at some point it might make sense to emit them as a separate css bundle so that they can be loaded in the html header.

var _wa_styles_ = {}

export default _wa_styles_;

