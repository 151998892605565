﻿import toastr from 'toastrjs';

var _wa_toast_ = function () {

}

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "50",
    "hideDuration": "50",
    "timeOut": "2500",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "swing",
    "showMethod": "slideDown",
    "hideMethod": "slideUp",
    "tapToDismiss:": false
}


_wa_toast_.toastError = function (message, title) {
    toastr.error(message, title, { "timeOut": 5000 });
}

_wa_toast_.toastSuccess = function (message, title) {
    toastr.success(message, title);
}

_wa_toast_.toastWarning = function (message, title) {
    toastr.warning(message, title);
}

_wa_toast_.toastInfo = function (message, title) {
    toastr.info(message, title);
}

export default _wa_toast_;

