﻿import "jquery-loadingModal"

// Currently uses loadingModal plugin, see https://www.npmjs.com/package/jquery-loadingModal

var _wa_loader_ = function () {
    
}

$.fn.showLoaderModal = function (message) {
    $(this).loadingModal('destroy').loadingModal({ text: message, 'animation': 'wanderingCubes', opacity: 0.6 });
}

$.fn.hideLoaderModal = function () {
    $(this).loadingModal('hide'); 
}

$.fn.destroyLoaderModal = function () {
    $(this).loadingModal('destroy');
}

export default _wa_loader_;

