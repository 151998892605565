﻿import $ from 'jquery';
import _wa_ajax_ from './webapp.ajax.js'
import _wa_dashboard_ from './webapp.dashboard'

var _wa_workspace_ = {}

_wa_workspace_.fadeInPage = function (contentPageSelector) {
    var $contentPage = $(contentPageSelector);
    $('#wa-content-page-title-id').text($contentPage.data('content-page-title'));
    $('#wa-content-page-description-id').text($contentPage.data('content-page-description'));
    $('#wa-content-page-category-id').text($contentPage.data('content-page-category'));
    $('#wa-content-page-entity-type-id').text($contentPage.data('content-page-entity-type'));
    $('#wa-content-page-icon-class-id').removeClass().addClass($contentPage.data('content-page-icon-class'));

    // Once fadeIn has started, the new page should (in theory) no longer be hidden and we can align table headers by redrawing.
    // To be sure headers are aligned, another table redraw is executed once the fadeIn has completed.

    $contentPage.webAppQueueTableRedraw(15); // Queued to start after fadeIn has begun
    $contentPage.fadeIn(150, function () { $contentPage.webAppDataTableRedraw(); }); 
}

_wa_workspace_.changeActiveContentPage = function (contentPageClass) {
    // TODO: Determine if already active/visible
    $('.sidebar-menu li').removeClass('active');
    $('.sidebar-menu .' + contentPageClass).addClass('active');

    var visible = $('div.wa-content-page:visible');

    var contentPageSelector = '.wa-content-page.' + contentPageClass;

    if (visible.length > 0) {
        // Note, first time a new page is loaded it is normally visible so it will be hidden here again along with the current page
        visible.fadeOut(50, function () { _wa_workspace_.fadeInPage(contentPageSelector) });
    } else {
        _wa_workspace_.fadeInPage(contentPageSelector) 
    }
    
    // Setting the height prevents jankiness where a newly downloaded page is briefly visible
    setTimeout(function () {
        // Wait for AdminLTE to adjust its heights before calculating content height
        // In the future, also/instead call this on some window change event or similar
        var contentHeaderHeight = Number($('.content-header').css('height').replace('px', ''));
        var contentWrapperHeight = Number($('.content-wrapper').css('height').replace('px', ''));
        $('#wa-main-content-container').css("height", contentWrapperHeight - contentHeaderHeight);

    }, 1000);

}

_wa_workspace_.isActiveContentPage = function (contentPageClass) {
    return $('.sidebar-menu .' + contentPageClass).hasClass('active');
}

_wa_workspace_.onGetContentPageSuccess_ = function (options, response, textStatus, $request) {
    _wa_ajax_.onRequestResponseSuccess(options, response, textStatus, $request);
    _wa_workspace_.changeActiveContentPage(options.selectedContentPageClass);
}

_wa_workspace_.isEntityLoaded = function (entityType, entityId) {
    var entityKey = entityId.toString();
    var entityPageClassSelector = '.wa-' + entityType + '-' + entityKey + '-page';
    return $('#wa-loaded-entities-list li' + entityPageClassSelector).length > 0;
}

_wa_workspace_.removeEntity = function (entityType, entityId) {
    var entityKey = entityId.toString();

    var entityPageClassSelector = '.wa-' + entityType + '-' + entityKey + '-page';

    var wasVisible = $('div' + entityPageClassSelector + ':visible').length > 0; // .css('display') != 'none'; 
    
    // Remove all pages and menuitems
    $('div' + entityPageClassSelector).remove();
    
    var sideBarItem = $("li" + entityPageClassSelector);
    if ((sideBarItem).parents("#wa-recent-entities-list").length) {
        sideBarItem.remove();
    } else {
        sideBarItem.prependTo($("#wa-recent-entities-list"));
    }

    if (wasVisible) {
        // TODO: Focus next entity or go to search workorder if empty?
        var $openEntities = $('#wa-loaded-entities-list li');

        if ($openEntities.length > 0) {
            var entityAnchor = $openEntities.first().find('a');
            _wa_workspace_.changeActiveContentPage(entityAnchor.data('content-page-container-class'));
        } else {
            // Possible introduce users preferred start page here in the future
            //_wa_workspace_.changeActiveContentPage('wa-workorder-search-page');
            var newContentPageAnchor = $('li.wa-workorder-assign-page').find('a');
            _wa_workspace_.switchToLinkedContentPage(newContentPageAnchor);
        }
        
    } 
}

_wa_workspace_.switchToLinkedContentPage = function(pageAnchor)
{
    var $anchor = $(pageAnchor);

    var selectedContentPageClass = $anchor.data("content-page-container-class");

    if (_wa_workspace_.isActiveContentPage(selectedContentPageClass))
        return;

    if ($('.wa-content-page.' + selectedContentPageClass).length) {
        _wa_workspace_.changeActiveContentPage(selectedContentPageClass);
    } else {

        var options = {
            onSuccess: _wa_workspace_.onGetContentPageSuccess_,
            selectedContentPageClass: selectedContentPageClass
        };

        $anchor.getAjax(options); 
    }
}


// Main content Switcher
$('body').on('click', '.wa-content-page-switcher', function (e) {
    e.preventDefault();
    _wa_workspace_.switchToLinkedContentPage(e.currentTarget);
});

// Main content Switcher
$('#wa-content-page-reload').on('click', function (e) {
    e.preventDefault();

    var selectedContentPageClass = $('.sidebar-menu li.active a').data('content-page-container-class');

    
    var url = $("div." + selectedContentPageClass).data('content-page-reload-url');

    if (url === undefined) {
        alert("Reloading is currently only supported for assign, coming for this page type real soon™");
        return;
    }

    // TODO: If entity, do we need to call entity cleanup before reload, or will it work anyway? (removing maps etc)
    var options = {
        url: url,
        onSuccess: _wa_workspace_.onGetContentPageSuccess_,
        selectedContentPageClass: selectedContentPageClass
    };

    $.fn.getAjax(options);

    
});

export default _wa_workspace_;

