﻿import $ from 'jquery';
import './webapp.formextensions.js';
import _wa_toast_ from "./webapp.toast"
import _wa_dialogs_ from "./webapp.dialogs"
import './webapp.loader.js';
import _wa_workspace_ from './webapp.workspace';
var _wa_ajax_ = {}


$.fn.invisible = function () {
            return this.each(function () {
                $(this).css("visibility", "hidden");
            });
}

$.fn.visible = function () {
            return this.each(function () {
                $(this).css("visibility", "visible");
            });
}


$.fn.ajaxRequest = function (options) {
    let defaultOptions = {
        dataType: 'json',
        async: true,
        onSuccess: _wa_ajax_.onRequestResponseSuccess
    };

    options = $.extend({}, defaultOptions, options);
    

    if (!options.loaderTarget)
        options.loaderTarget = $('body');

    if (!options.loaderMessage)
        options.loaderMessage = 'Kontaktar servern, var god vänta...';

    $(options.loaderTarget).showLoaderModal(options.loaderMessage);
    options.loaderStartTime = new Date();

    // Note: Toast messages are processed after the optional user callbacks, heavy workload may otherwise break the asynchronous animation
    $.ajax({
        type: options.type,
        async: options.async,
        url: options.url,
        data: options.data,
        processData: options.processData,
        dataType: options.dataType,
        contentType: options.contentType,
        cache: options.cache,
        success: function (response, textStatus, $request) {
            if (response.redirectUrl != null)
                $(location).attr('href', response.redirectUrl);

            if (options.onSuccess) {
                options.onSuccess(options, response, textStatus, $request);
            }

            if (response.message !== null) {
                _wa_toast_.toastSuccess(response.message);
            }

            if (response.errorMessage !== null) {
                _wa_toast_.toastError(response.errorMessage);
            } else if (options.afterSuccess) {
                options.afterSuccess(options, response, textStatus, $request);
            }

        },
        error: function ($request, textStatus, errorThrown) {
            if ($request.status === 401 || $request.status === 400) // Unauthorized or Bad Request (invalid token)
                $(location).attr('href', "/Account/Login/");
            
            if (options.onError) {
                options.onError(options, $request, textStatus, errorThrown);
            }

            if ($request.status === 0) { // TODO: Move these strings as part of element on layout page to allow for translation
                _wa_toast_.toastError("Servern går ej att nå för tillfället", "Fel vid anrop till server");
            } else if ($request.status === 403) {
                _wa_toast_.toastError(textStatus, "Åtkomst nekad");
            } else {
                _wa_toast_.toastError(textStatus, "Felaktig eller saknad data i formulär");
            }
        }
    }).always(function () {
        if (options.always) {
            options.always();
        }
        var requestDuration = new Date() - options.loaderStartTime; 
        if (requestDuration > 750 )
            $(options.loaderTarget).hideLoaderModal();
        else
            $(options.loaderTarget).destroyLoaderModal(); // Skip fadeout animation
    });

};



//TODO: add ability to post json with contentType: 'application/json; charset=utf-8',
$.fn.postAjax = function (options) {
    var postOptions = { type: 'POST', cache: false, processData: true, contentType: 'application/x-www-form-urlencoded; charset=utf-8'};

    options = $.extend({}, options, postOptions);

    // TODO: Get url and data from options, if not found in options, use form
    if (!options.url)
        options.url = this.attr('action');

    if (this.attr('enctype') === 'multipart/form-data') {
        options.contentType = false;
        options.data = new FormData(this[0]);
        options.processData = false;
        //var form = $('form')[0]; // You need to use standard javascript object here
        //var formData = new FormData(form);
        //options.contentType = 'multipart/form-data';
    } else {
        if (!options.data)
            options.data = this.serialize();
    }
    
    $.fn.ajaxRequest(options);

};

$.fn.getAjax = function (options) {
    var getOptions = { type: 'GET', cache: false, processData: true, contentType: 'application/json; charset=utf-8' };

    options = $.extend({}, options, getOptions);

    if (!options.url)
        options.url = this.attr('href');

    if (!options.data)
        options.data = this.serialize();
    //options.data = this.data('entityId');

    $.fn.ajaxRequest(options);
};


_wa_ajax_.onRequestResponseSuccess = function (options, response, textStatus, $request) {
    
    $.each(response.contents, function (c, content) {
        $.each(content.instructions, function (i, instruction) {

            if (instruction.remove !== null) {
                var $element = $(instruction.remove).remove();
                $element.trigger("content.removed.ajax.wa", instruction.remove);
            }

            

            if (instruction.replace !== null) {
                var $element = $(content.data).replaceAll($(instruction.replace));
                $element.formExtensions();
                $element.trigger("content.replaced.ajax.wa", instruction.replace);

            }

            if (instruction.replaceInnerFor !== null) {
                var $element = $(instruction.replaceInnerFor);
                $element.html(content.data).formExtensions();
                $element.trigger("inner.content.replaced.ajax.wa", instruction.replaceInnerFor);
                //Alert('test!');
                //$element = $(content.data);
               // $form = $element.closest('form');
               // $form.validate().form();

            }
            if (instruction.processJSON !== null) {
                var $element = $.parseJSON(content.data);
                options.processJSON($element);
                //$element.dispatchEvent(new CustomEvent('contentInserted', { detail: instruction.insertInto }));
            }
            if (instruction.prependTo !== null) {
                var $element = $(content.data).prependTo(instruction.prependTo);
                $element.formExtensions();
                //$(instruction.insertInto).formExtensionsss();
                $element.trigger("content.inserted.ajax.wa", instruction.prependTo);
                //$element.dispatchEvent(new CustomEvent('contentInserted', { detail: instruction.insertInto }));
            }

            if (instruction.insertInto !== null) {
                var $element = $(content.data).appendTo(instruction.insertInto);
                $element.formExtensions();
                //$(instruction.insertInto).formExtensionsss();
                $element.trigger("content.inserted.ajax.wa", instruction.insertInto);
                if (instruction.switchTo !== null) {
                    _wa_workspace_.changeActiveContentPage(instruction.switchTo)

                }

                //$element.dispatchEvent(new CustomEvent('contentInserted', { detail: instruction.insertInto }));
            }

            if (instruction.showModalDialogOver !== null) {
                var $element = $(instruction.showModalDialogOver);
                if (instruction.isLocalDialog === true)
                    $element.showLocalModalDialog({ html: content.data });
                else
                    $element.showModalDialog({ html: content.data });
                    
                //.formExtensions();
                $element.trigger("modal.dialog.shown.over.ajax.wa", instruction.showModalDialogOver);
            }

        });
    });

}

export default _wa_ajax_;
