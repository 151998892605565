﻿import _wa_workspace_ from './webapp.workspace';

var _wa_alert_ = {


}

_wa_alert_.load = function (alertIds) {

    if (alertIds.length === 0) {
        return;
    }

    var unloadedIds = [];
    var newActiveContentPage = 'wa-alert-' + alertIds[0] + '-page';

    alertIds.forEach(function (alertId) {
        if (!_wa_workspace_.isEntityLoaded('alert', alertId))
            unloadedIds.push(alertId);
    });

    if (unloadedIds.length === 0) {
        _wa_workspace_.changeActiveContentPage(newActiveContentPage);
        return;
    }

    var data = { alertIds: unloadedIds };


    var options = {
        url: $('#wa-load-alert-url').attr('href'),
        data: data,
        loaderTarget: $('div.wa-alert-search-page'), // TODO Find the closest content page instead
        loaderMessage: 'Hämtar Felnotifieringar...',
        afterSuccess: function () {
            _wa_workspace_.changeActiveContentPage(newActiveContentPage);
        }
    };
    $.fn.postAjax(options);

}

_wa_alert_.unLoad = function (id) {
    var entityKey = id.toString();
    _wa_workspace_.removeEntity('alert', id);
}

$(document).on('click', 'span.wa-unload-alert-button', function (event) {
    var $button = $(event.currentTarget);
    _wa_alert_.unLoad($button.data('entity-id'));
});

export default _wa_alert_;
