﻿import Map from './webapp.bingmaps';
import _wa_datatables_ from "./webapp.datatables";
import _wa_toast_ from "./webapp.toast";
import _wa_workorder_ from "./webapp.workorder"

var _wa_assign_ = {
  map: null
}

_wa_assign_.getSelectionData = function ($table, $dataTable) {
    var selectedRowIndexes = $dataTable.rows({ selected: true }).indexes();
    var selectedWorkorderIds = $dataTable.cells(selectedRowIndexes, 'workorderId:name').data().toArray();

    var unselectedRowIndexes = $dataTable.rows({ selected: false }).indexes();
    var unselectedWorkorderIds = $dataTable.cells(unselectedRowIndexes, 'workorderId:name').data().toArray();
    
    var data = {
        selectedWorkorderIds: selectedWorkorderIds,
        unselectedWorkorderIds: unselectedWorkorderIds,
        contentInstruction: { replaceInnerFor: '#wa-workorder-assign-search-result-container' }
    };

    return data;
}

/* Assign button */
_wa_assign_.addAssignButton = function ($table, $dataTable) {
    var $assignButton = $('#wa-button-templates .wa-assign-workorder-button').clone();

    $assignButton.on('click', function () {
        var data = _wa_assign_.getSelectionData($table, $dataTable);

        if (data.selectedWorkorderIds.length === 0) {
            _wa_toast_.toastInfo('Minst en order måste väljas', 'Inga ordrar att tilldela');
            return;
        }

        var options = {
            url: $('#wa-assign-workorder-dialog-url').attr('href'),
            data: $.param(data),
            loaderTarget: $('div.wa-workorder-assign-page'),
            loaderMessage: 'Kontrollerar status för utvalda ordrar...'

        };
        $.fn.postAjax(options);
    });

    _wa_datatables_.addFooterItem($table, $assignButton);
}

_wa_assign_.addRecallButton = function ($table, $dataTable) {
    var $recallButton = $('#wa-button-templates .wa-recall-workorder-button').clone();

    $recallButton.on('click', function () {
        var data = _wa_assign_.getSelectionData($table, $dataTable);

        if (data.selectedWorkorderIds.length === 0) {
            _wa_toast_.toastInfo('Minst en order måste väljas', 'Inga ordrar att återkalla');
            return;
        }

        var options = {
            url: $('#wa-recall-workorder-dialog-url').attr('href'),
            data: $.param(data),
            loaderTarget: $('div.wa-workorder-assign-page'),
            loaderMessage: 'Kontrollerar status för utvalda ordrar...'

        };
        $.fn.postAjax(options);
    });

    _wa_datatables_.addFooterItem($table, $recallButton);

}


_wa_assign_.addCloseButton = function ($table, $dataTable) {
    var $closeButton = $('#wa-button-templates .wa-close-workorder-button').clone();

    $closeButton.on('click', function (e) {
        var data = _wa_assign_.getSelectionData($table, $dataTable);
        var $url = $(e.target).data('url');
        if (data.selectedWorkorderIds.length === 0) {
            _wa_toast_.toastInfo('Minst en order måste väljas', 'Inga ordrar att stänga');
            return;
        }

        var options = {
            url: $('#wa-close-workorder-dialog-url').attr('href'),
            data: $.param(data),
            loaderTarget: $('div.wa-workorder-assign-page'),
            loaderMessage: 'Kontrollerar status för utvalda ordrar...'

        };
        $.fn.postAjax(options);
    });

    _wa_datatables_.addFooterItem($table, $closeButton);

}

_wa_assign_.addOpenButton = function ($table, $dataTable) {
    var $openButton = $('#wa-button-templates .wa-open-workorder-button').clone();

    $openButton.on('click', function () {
        var selectedRowIndexes = $dataTable.rows({ selected: true }).indexes();
        var selectedWorkorderIds = $dataTable.cells(selectedRowIndexes, 'workorderId:name').data().toArray();
   
        _wa_workorder_.load(selectedWorkorderIds);
    });

    _wa_datatables_.addToolbarItem($table, $openButton);

}

// Attaches event invoked after search result table is displayed
$('#wa-main-content-container').on('init.dt', '#wa-workorder-assign-search-result-table' , function (e) {
    var $table = $(e.target);
    var $dataTable = $table.DataTable();

    if ($('#wa-has-manage-workorder-assign-authorization').length > 0)
    {
        _wa_assign_.addAssignButton($table, $dataTable);
        _wa_assign_.addRecallButton($table, $dataTable);
        _wa_assign_.addCloseButton($table, $dataTable);
    }

    _wa_datatables_.addSelectAllButton($table, $dataTable);
    _wa_assign_.addOpenButton($table, $dataTable);
    //_wa_assign_.addRefreshButton($table, $dataTable);

    var refreshButtonOptions = { 
        idColumnSelector: 'workorderId:name',
        tableContainerSelector: '#wa-workorder-assign-search-result-container',
        refreshActionUrl: $('#wa-search-assign-workorder-url').attr('href'),
        loaderTargetSelector: 'div.wa-workorder-assign-page'
    };

    _wa_datatables_.addRefreshButton($table, $dataTable, refreshButtonOptions);





    if (_wa_assign_.map !== null)
        _wa_assign_.initMap();
    
});

_wa_assign_.selectAllFeatures = function (workorderIds) {
    let ids = [];

    workorderIds.forEach(function (workorderId) { ids.push('#awrid' + workorderId) });
    let $dataTable = $('#wa-workorder-assign-search-result-table').DataTable();
    $dataTable.rows(ids).select();
}

_wa_assign_.onMapSelection = function (selectEvent) {
    let $dataTable = $('#wa-workorder-assign-search-result-table').DataTable();

    if (selectEvent.selected.length > 0) {
        let rowIds = [];
        selectEvent.selected.forEach(function (feature) { rowIds.push('#awrid' + feature.getId()) });
        $dataTable.rows(rowIds).select();
    }

    if (selectEvent.deselected.length > 0) {
        let rowIds = [];
        selectEvent.deselected.forEach(function (feature) { rowIds.push('#awrid' + feature.getId()) });
        $dataTable.rows(rowIds).deselect();
    }
}

_wa_assign_.initMap = function(){
    let $table = $('#wa-workorder-assign-search-result-table');

    if (_wa_assign_.map === null) {
        _wa_assign_.map = new Map({ elementId: 'assign-map' });    
    } else {
        _wa_assign_.map.removeAllFeatures();
    }

    if ($('#assign-map canvas').length === 0) { // TODO: Add a method to map for checking if displayed instead
        _wa_assign_.map.init(_wa_assign_);
    }

    if ($table.length === 0)
        return;

    var $dataTable = $table.DataTable();

    var columnData = $dataTable.columns(['workorderId:name', 'applicationXcoordinate:name', 'applicationYcoordinate:name', "isAssigned:name"]).data();
    _wa_assign_.map.addFeatures(columnData[0], columnData[1], columnData[2], columnData[3]);

    var selectedRows = $dataTable.rows({ selected: true });
    if (selectedRows.count() > 0) {
        var workorderIdColumnIndex = $dataTable.column('workorderId:name').index();
        var selectedRowIndexes = selectedRows.indexes().toArray();
        var data = $dataTable.data();

        selectedRowIndexes.forEach(function (rowIndex) {
            var workorderId = data[rowIndex][workorderIdColumnIndex];
            _wa_assign_.map.selectFeatureById(workorderId);
        });
    }

    _wa_assign_.map.zoomToExtent();


    // Update map points on Table Select Event

    $dataTable.on('select.dt', function (e, dt, type, indexes) {

        if (type === 'row') {
            var data = $dataTable.cells(indexes, 'workorderId:name').data();
            data.toArray().forEach(_wa_assign_.map.selectFeatureById, _wa_assign_.map);
        }
    });

    // Update map points on Table Deselect Event
    $dataTable.on('deselect.dt', function (e, dt, type, indexes) {
        if (type === 'row') {
            var data = $dataTable.cells(indexes, 'workorderId:name').data();
            data.toArray().forEach(_wa_assign_.map.deselectFeatureById, _wa_assign_.map);
        }
    });
    
}

$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
    if (!$(e.target).is('#wa-assign-workorder-map-tab'))
        return;

    _wa_assign_.initMap();
});



//assign
$("#wa-main-content-container").on('change', '.js-assign-technician', function (e,forward) {
    //$('js-assign-technician', this).on('change', function (e) {
    if (typeof forward !== 'undefined')
        return;
    else {
        var $parentContainer = $(e.target).closest('div.row');
        var $workteams = $parentContainer.find('.js-assign-workteam');
        $workteams.val(null).trigger("change", false);
    }    
    
});

$("#wa-main-content-container").on('change', '.js-assign-workteam', function (e,forward) {
    //$('js-assign-technician', this).on('change', function (e) {
    if (typeof forward !== 'undefined')
        return;
    else {
        var $parentContainer = $(e.target).closest('div.row');
        var $technicians = $parentContainer.find('.js-assign-technician');
        $technicians.val(null).trigger("change", false);
    }


});




$(document).on('click', 'button.wa-perform-close-button', function (event) {
    var $form = $(event.currentTarget).closest('form.wa-close-workorder-form');
    if (!$form.validate().form()) {
        event.preventDefault();
        return;
    }

    var data = $form.serializeArray();

    var $assignDataTable = $form.find('.wa-selected-workorders-table').DataTable();
    var selectedWorkorderIds = $assignDataTable.column('workorderId:name').data().toArray();

    selectedWorkorderIds.forEach(function (workorderId, i) {
        data.push({ name: "selectedWorkorderIds[" + i + "]", value: workorderId })
    });

    var options = {
        url: $form.attr('action'),
        data: $.param(data),
        loaderTarget: $('div.wa-workorder-assign-page'), // TODO Find the closest dialog instead
        loaderMessage: "Stänger ordrar...",
        afterSuccess: function () {
            $form.closest('.ui-dialog-content').dialog('close');
        }
    };
    $.fn.postAjax(options);



});
$(document).on('click', 'button.wa-perform-assign-button', function (event) {
    var $form = $(event.currentTarget).closest('form.wa-assign-workorder-form');
    var data = $form.serializeArray();

    var $assignDataTable = $form.find('.wa-selected-workorders-table').DataTable();
    var selectedWorkorderIds = $assignDataTable.column('workorderId:name').data().toArray();

    selectedWorkorderIds.forEach(function (workorderId, i) {
        data.push({ name: "selectedWorkorderIds[" + i + "]", value: workorderId })
    });

    var options = {
        url: $form.attr('action'),
        data: $.param(data),
        loaderTarget: $('div.wa-workorder-assign-page'), // TODO Find the closest dialog instead
        loaderMessage: "Delar ut ordrar...",
        afterSuccess: function () {
            $form.closest('.ui-dialog-content').dialog('close');
        }
    };
    $.fn.postAjax(options);
    
    
    
});

$(document).on('click', 'button.wa-perform-recall-button', function (event) {
    var $form = $(event.currentTarget).closest('form.wa-recall-workorder-form');
    var data = $form.serializeArray();

    var $assignDataTable = $form.find('.wa-selected-workorders-table').DataTable();
    var selectedWorkorderIds = $assignDataTable.column('workorderId:name').data().toArray();

    selectedWorkorderIds.forEach(function (workorderId, i) {
        data.push({ name: "selectedWorkorderIds[" + i + "]", value: workorderId })
    });
    /*for (var i = 0; i < 1024; i++) {
        data.push({ name: "UnSelectedWorkorderIds[" + i + "]", value: 1 })    }
    for (var i = 0; i < 2024; i++) {
        data.push({ name: "selectedWorkorderIds[" + i + "]", value: 1 })
    }*/

    var options = {
        url: $form.attr('action'),
        data: $.param(data),
        loaderTarget: $('div.wa-workorder-assign-page'), // TODO Find the closest dialog instead
        loaderMessage: 'Återkallar ordrar...',
        afterSuccess: function () {
            $form.closest('.ui-dialog-content').dialog('close');
        }
    };
    $.fn.postAjax(options);

});


export default _wa_assign_;
