﻿import _wa_datatables_ from "./webapp.datatables";
import _wa_toast_ from "./webapp.toast";

var _wa_fileimport_ = {

}

// Attaches event invoked after search result table is displayed
$("#wa-main-content-container").on('init.dt', "#wa-file-import-search-result-table", function (e) {
    var $table = $(e.target);
    var $dataTable = $table.DataTable();

    _wa_datatables_.addSelectAllButton($table, $dataTable);

    var refreshButtonOptions = {
        idColumnSelector: 'fileId:name',
        tableContainerSelector: '#wa-file-import-search-result-container',
        refreshActionUrl: $('#wa-file-import-search-url').attr('href'),
        loaderTargetSelector: 'div.wa-file-import-management-content-page'
    };

    _wa_datatables_.addRefreshButton($table, $dataTable, refreshButtonOptions);

});


$(document).on('change', ':file', function () {
    var input = $(this)
    var fileName = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    var textInput = $(this).parents('.input-group').find(':text').val(fileName);

    /*
    var input = $(this),
        numFiles = input.get(0).files ? input.get(0).files.length : 1,
        label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    input.trigger('fileselect', [numFiles, label]);
    */
});

// We can watch for our custom `fileselect` event like this
/*
$(document).ready(function () {
    $(':file').on('fileselect', function (event, numFiles, label) {

        var input = $(this).parents('.input-group').find(':text'),
            log = numFiles > 1 ? numFiles + ' files selected' : label;

        if (input.length) {
            input.val(log);
        } else {
            if (log) alert(log);
        }

    });
});
*/