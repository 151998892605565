﻿import _wa_datatables_ from "./webapp.datatables";
import _wa_toast_ from "./webapp.toast";
import _wa_alert_ from "./webapp.alert";

var _wa_alertsearch_ = {

}

_wa_alertsearch_.addOpenButton = function ($table, $dataTable) {
    var $openButton = $('#wa-button-templates .wa-open-alert-button').clone();

    $openButton.on('click', function () {
        var selectedRowIndexes = $dataTable.rows({ selected: true }).indexes();
        var selectedAlertIds = $dataTable.cells(selectedRowIndexes, 'alertId:name').data().toArray();

        _wa_alert_.load(selectedAlertIds);
    });

    _wa_datatables_.addToolbarItem($table, $openButton);

}


// Attaches event invoked after search result table is displayed
$('#wa-main-content-container').on('init.dt', '#wa-alert-search-result-table', function (e) {
    var $table = $(e.target);
    var $dataTable = $table.DataTable();

    _wa_datatables_.addSelectAllButton($table, $dataTable);
    _wa_alertsearch_.addOpenButton($table, $dataTable);
    //_wa_assign_.addRefreshButton($table, $dataTable);

    var refreshButtonOptions = {
        idColumnSelector: 'alertId:name',
        tableContainerSelector: '#wa-alert-search-result-container',
        refreshActionUrl: $('#wa-search-alerts-url').attr('href'),
        loaderTargetSelector: 'div.wa-alert-search-page'
    };

    _wa_datatables_.addRefreshButton($table, $dataTable, refreshButtonOptions);

});
