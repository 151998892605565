﻿import $ from 'jquery';
//import 'jquery-ui-dist/jquery-ui';
import 'jquery-ui';
import './webapp.formextensions.js';

var _wa_dialogs_ = function () {

}

$.fn.showModalDialog = function (options) {
    var $dialogContent = $(options.html);
    var $this = $(this);
    // 'div.wa-assign-workorder-content-page'

    var $dialog = $(options.html).dialog({
        appendTo: $this,
        minWidth: 1024,
        maxWidth: 1024,
        maxHeight: 900,
        minHeight: 300,
        position: { my: "center", at: "center", of: $this},
        resizable: true,
        modal: true,
        title: $dialogContent.data("title"),
        closeOnEscape: true,
        show: { effect: "clip", duration: 300 },
        hide: { effect: "scale", duration: 300 },
        close: function (event, ui) {
            $(event.target).empty().remove();

        }
    });

     $dialog.parent().draggable({
         containment: $this
    });

    $dialog.formExtensions({ select2: { dropdownParent: $dialog } }); // Modal dropdown search fix
    $.validator.unobtrusive.parse($dialog);
    $dialog.closest('form').validate();
    
    

    $dialog.on('click', 'button.wa-close-dialog-button', function (event) {
        $dialog.dialog('close');
    });
    

    return this;
}

$.fn.showLocalModalDialog = function (options) {
    var $dialogContent = $(options.html);
    var $this = $(this);
    // 'div.wa-assign-workorder-content-page'

    var $dialog = $(options.html).dialog({
        appendTo: $this,
        height: 'auto',
        width:'auto',
        /*minWidth: 900,
        maxWidth: 900,
        maxHeight: 700,
        minHeight: 100,*/
        position: { my: "center", at: "center", of: $this },
        resizable: false,
        modal: true,
        title: $dialogContent.data("title"),
        closeOnEscape: true,
        show: { effect: "clip", duration: 300 },
        hide: { effect: "scale", duration: 300 },
        close: function (event, ui) {
            $(event.target).empty().remove();

        }
    });

    $dialog.parent().draggable({
        containment: $this
    });


    $dialog.formExtensions({ select2: { dropdownParent: $dialog } }); // Modal dropdown search fix
    $.validator.unobtrusive.parse($dialog);
    $dialog.find('form').validate();
   /* $dialog.on('click', 'button.wa-close-dialog-button', function (event) {
        options.update($(event.target));
        $dialog.dialog('close');
    });*/
    

    return $dialog;
}





export default _wa_dialogs_;