﻿import 'admin-lte-bootstrap';
import './webapp.tooltips'
import 'jquery-ui'; // Jquery-ui is loaded here early due to bootstrap-datepicker conflict issues
import 'admin-lte';

//import 'select2/dist/js/select2.full.js';

var _wa_dashboard_ = {}

export default _wa_dashboard_;


$(document).on('collapsed.pushMenu', function (e) {
    $('div.wa-content-page:visible').webAppQueueTableRedraw();
});

$(document).on('expanded.pushMenu', function (e) {
    $('div.wa-content-page:visible').webAppQueueTableRedraw();
});

/*
_wa_dashboard_.queueResizeUpdate = function (delayBeforeUpdate) {

    if (!delayBeforeUpdate)
        delayBeforeUpdate = 300;

    setTimeout(function () {
        $('body').webAppDataTableRedraw();
    }, delayBeforeUpdate);
}
*/