﻿


import $ from 'jquery';
//import 'chart.js-plugin-labels-dv/dist/chartjs-plugin-labels.min.js';
import _wa_localization_ from './webapp.localization.js';
import _wa_datatables_ from './webapp.datatables.js';
//import './webapp.checkboxes.js';
import 'select2/dist/js/select2.full.js';
import 'moment/dist/moment.js';
import 'daterangepicker';
//import 'tempusdominus-bootstrap-4';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.sv.min.js';
import _wa_ajax_ from './webapp.ajax.js'
//import ChartDataLabels  from 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min';
import _wa_startup_ from './webapp.js';
// Make sure Jquery UI is loaded before bootstrap-datepicker as loading JqueryUI after breaks language & dateformat support
var _wa_formextensions_ = {}

// Jquery Validation Fixes for decimals
$.validator.methods.range = function (value, element, param) {
    var globalizedValue = value.replace(",", ".");
    return this.optional(element) || (globalizedValue >= param[0] && globalizedValue <= param[1]);
}

$.validator.methods.number = function (value, element) {
    return this.optional(element) || /^-?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value);
}


$.fn.formExtensionsss = function (options) {
    $('.select2,.select2-single,.select2-multiple', this).select2({
        width: null,
        containerCssClass: ':all:'
    }
        //select2Options
        //{ dropdownParent: $('#wa-assign-workorder-dialog') }
    );

}
$.fn.formExtensions = function (options) {
    options = $.extend(options, $.fn.formExtensions.defaults);
    $.fn.select2.defaults.set("theme", "bootstrap");
    let select2Options = {};
    $("div.input-group-prepend[data-select2-open]").click(function () {
        $("#" + $(this).data("select2-open")).select2("open");
    });
    $.extend(select2Options, options.select2);

    $(function () {
        $('[data-toggle="tooltip"]', this).tlp({ trigger: 'hover', delay: 400 });
    });
    /*
    if (options.select2) {
        if (options.select2.dropdownParent) {
            select2Options.dropdownParent = $(options.select2.dropdownParent)
        }
    }
    */
    //allowClear: true // Works only for multi select boxes, not dropdown
    //dropdownParent: $('.ui-dialog') 

    //Initialize Select2 Elements
    $('.select2,.select2-single,.select2-multiple', this).select2({
        width: null,
        containerCssClass: ':all:'
    }
        //select2Options
        //{ dropdownParent: $('#wa-assign-workorder-dialog') }
    );

    /*    $('.select2-single', this).select2({
            width: null,
            containerCssClass: ':all:'}
        );
        */
    var dateformat = 'yyyy/mm/dd';
    /*   if (_wa_localization_.getCurrentLanguage() === 'sv') {
           dateformat = 'yyyy/mm/dd';
       }
       else {
           dateformat = 'dd/mm/yyyy';
       }*/

    $('.datepicker', this).datepicker({
        weekStart: 1,
        todayHighlight: true,
        calendarWeeks: true,
        autoclose: true,
        todayBtn: "linked",
        language: _wa_localization_.getCurrentLanguage(),
        format: dateformat
    }).attr('type', 'text'); // Native chrome datepicker fix


    var elems = $('.date-range', this);
    /*  $('#reportrange',this).click(function () {
          $(this).parent().find('input').click();
      });*/
    var daterange_locale;
    var daterange_ranges;
    if (_wa_localization_.getCurrentLanguage() === 'sv') {
        daterange_locale = {
            //            direction: $('#rtl').is(':checked') ? 'rtl' : 'ltr',
            format: 'YYYY/MM/DD',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Rensa',
            fromLabel: 'Från',
            toLabel: 'Till',
            customRangeLabel: 'Välj datum',
            daysOfWeek: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
            monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
            firstDay: 1
        };
        daterange_ranges = {
            'Rensa': ['', ''],
            'Idag': [moment(), moment()],
            'Igår': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Senaste 7 dagar': [moment().subtract(6, 'days'), moment()],
            'Senaste 30 dagar': [moment().subtract(29, 'days'), moment()],
            'Denna månad': [moment().startOf('month'), moment().endOf('month')],
            'Förra månaden': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

        };
    }
    else {
        daterange_locale = {
            //            direction: $('#rtl').is(':checked') ? 'rtl' : 'ltr',
            format: 'YYYY/MM/DD',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Clear',
            //fromLabel: 'Från',
            //toLabel: 'Till',
            customRangeLabel: 'Choose Range',
            //daysOfWeek: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
            //monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
            firstDay: 1
        };
        daterange_ranges = {
            'Clear': ['', ''],
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 days': [moment().subtract(6, 'days'), moment()],
            'Last 30 days': [moment().subtract(29, 'days'), moment()],
            'This month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

        };

    }
    elems.daterangepicker({
         //startDate: moment().toDate(),
        //endDate: moment().toDate(),
        //parentEl: '.tab-pane',
        //   alwaysShowCalendars:false,
        autoUpdateInput: false,
        autoApply: true,
        showDropdowns: true,
        //    autoUpdateInput:true,
        showISOWeekNumbers: true,
        linkedCalendars: false,
        showCustomRangeLabel: true,
        //startDate: "06/15/2022",
        //endDate: "06/21/2022",
        locale: daterange_locale,
        ranges: daterange_ranges
    }, function (start, end, label) {
        //console.log("New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')");
    });
    elems.on('apply.daterangepicker', function (ev, picker) {
        if (picker.startDate._isValid == true && picker.endDate._isValid == true) {
            $(this).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'));
        }
        else {
            $('div.daterangepicker .cancelBtn').click();
        }
    });

    elems.on('cancel.daterangepicker', function (ev, picker) {
        $(this).val('');
    });
    //    elems.val('');




    /*
    $(".wa-error-measurements-table", this).on('init.dt', function (e) {
        var $table = $(e.target);
        var $dataTable = $table.DataTable({ "bRetrieve": true });
        $dataTable.column(0).visible(false);
        
    });
    */
    /*        let tehLabels = [];
            let dataAr = [];
            let lineLabels = [];
                    var $totalColor = $('#wa-colors .dashboard1-total').data('color-value');
            var $failedRVColor = $('#wa-colors .dashboard1-total-frv').data('color-value');
            var $successRVColor = $('#wa-colors .dashboard1-total-srv').data('color-value');
    var $visitorsChart = $('#visitors-chart');
    */
    var processStatistics = function (data, tehLabels, dataAr, lineLabels, types) {

        data.Labels.forEach(label => {
            tehLabels.push(label);
        });
        data.Types.forEach(type => {
            types.push(type);
        });

        data.Data.forEach(dataSet => {
            let tmpData = [];
            lineLabels.push(dataSet.Item1);
            dataSet.Item2.forEach(dataValue => {
                tmpData.push(dataValue);
            });
            dataAr.push(tmpData);
        });

    };

    var $tehfunc = function () {
        'use strict'
        var ticksStyle = { fontColor: '#495057', fontStyle: 'bold' }
        var mode = 'index'
        var intersect = true
        var $salesChart = $('#multiple-technicians-chart')
        var salesChart = new Chart($salesChart, {
            type: 'bar', data: { labels: ['JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'], datasets: [{ backgroundColor: '#007bff', borderColor: '#007bff', data: [1000, 2000, 3000, 2500, 2700, 2500, 3000] }, { backgroundColor: '#ced4da', borderColor: '#ced4da', data: [700, 1700, 2700, 2000, 1800, 1500, 2000] }] }, options: {
                maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, legend: { display: false }, scales: {
                    yAxes: [{
                        gridLines: { display: true, lineWidth: '4px', color: 'rgba(0, 0, 0, .2)', zeroLineColor: 'transparent' }, ticks: $.extend({
                            beginAtZero: true, callback: function (value) {
                                if (value >= 1000) {
                                    value /= 1000
                                    value += 'k'
                                }
                                return '$' + value
                            }
                        }, ticksStyle)
                    }], xAxes: [{ display: true, gridLines: { display: false }, ticks: ticksStyle }]
                }
            }
        });
    };

    var generate2DGraph = function (chartType, types, $chart, lineColors, tehLabels, dataAr, lineLabels, doSteps) {
        var ticksStyle = { fontColor: '#495057', fontStyle: 'bold' };
        var mode = 'index';
        var intersect = true;

        const topLabels = {
            id: 'topLabels',
            afterDatasetsDraw(chart, args, pluginOptions) {
                const { ctx, scales: { x, y } } = chart;
                chart.data.datasets[0].data.forEach((datapoint, index) => {
                    const datasetArray = [];
                    chart.data.datasets.forEach((dataset) => {
                        datasetArray.push(dataset.data[index]);
                    });
                    //console.log(datasetArray);
                    function totalSum(total, values) {
                        return total + values;
                    };
                    let sum = datasetArray.reduce(totalSum, 0);
                    sum = sum - datasetArray[3]
                    ctx.font = 'bold 12px sans-serif';
                    ctx.fillStyle = '#007bff';
                    ctx.textAlign = 'center';
                    ctx.fillText(sum, x.getPixelForValue(index), chart.getDatasetMeta(chart.data.datasets.length - 2).data[index].y );

                });

            }
        };
        let dataSets = [];
        $(dataAr).each(function (index, element) {
            //let dataSetLine = { type: 'line', order: 1, label: lineLabels[index], data: element, backgroundColor: 'transparent', borderColor: lineColors[index], pointBorderColor: lineColors[index], pointBackgroundColor: lineColors[index], fill: false, steppedLine: doSteps };
            //let dataSetBar = { type: 'bar', order: 2, label: lineLabels[index], backgroundColor: lineColors[index], borderColor: lineColors[index], pointRadius: false, pointColor: lineColors[index], pointStrokeColor: lineColors[index], pointHighlightFill: '#fff', pointHighlightStroke: lineColors[index], data: element };

            let dataSetLine = { type: 'line', order: 1, label: lineLabels[index], data: element, backgroundColor: 'transparent', borderColor: lineColors[index], pointBorderColor: lineColors[index], pointBackgroundColor: lineColors[index], fill: false, steppedLine: doSteps };
            let dataSetBar = { type: 'bar', order: 2, backgroundColor: lineColors[index], borderColor: lineColors[index], pointRadius: false, pointColor: lineColors[index], pointStrokeColor: lineColors[index], pointHighlightFill: '#fff', pointHighlightStroke: lineColors[index], data: element };
            if (types[index] === 'line') {
                dataSets.push(dataSetLine);
            }
            else {
                dataSets.push(dataSetBar);
            }
        });
        let xAxisOptions = [];
        let yAxisOptions = [];
        if (chartType === 'line') {

            xAxisOptions.push({ gridLines: { display: false }, ticks: ticksStyle });
            yAxisOptions.push({ gridLines: { display: true, lineWidth: '4px', color: 'rgba(0, 0, 0, .2)', zeroLineColor: 'transparent' }, ticks: $.extend({ beginAtZero: true }, ticksStyle) });
        }
        else {
            xAxisOptions.push({ stacked: true });
            yAxisOptions.push({ stacked: true, grace: 2 });
        }
        /*
        if (chartType === 'linee') {
            var chart = new Chart($chart, { type: chartType, data: { labels: tehLabels, datasets: dataSets }, options: { maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, legend: { display: true, position: 'bottom' }, scales: { yAxes: yAxisOptions, xAxes: xAxisOptions } } });
        }
        else {
            var chart = new Chart($chart, { data: { labels: tehLabels, datasets: dataSets }, options: { maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, legend: { display: true, position: 'bottom' }, scales: { yAxes: yAxisOptions, xAxes: xAxisOptions } } });
        }
        intersect = true;
        xAxisOptions.push({ stacked: true }, { display: false, gridLines: { display: false }, ticks: ticksStyle });
        yAxisOptions.push({ stacked: true }, { display: false, gridLines: { display: false, lineWidth: '4px', color: 'rgba(0, 0, 0, .2)', zeroLineColor: 'transparent' }, ticks: $.extend({ beginAtZero: true }, ticksStyle) });
        //var chart = new Chart($chart, { type:'bar',data: { labels: tehLabels, datasets: dataSets }, options: { maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, legend: { display: true, position: 'bottom' }, scales: { x: xAxisOptions, y: yAxisOptions } } });
        //var chart = new Chart($chart, { type: 'bar', data: { labels: tehLabels, datasets: dataSets }, options: { maintainAspectRatio: false,  legend: { display: true, position: 'bottom' }, scales: { x: xAxisOptions, y: yAxisOptions } } });
/*        var chart = new Chart($chart, {
            type: 'bar', data: { labels: tehLabels, datasets: dataSets }, options: {
                maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, legend: { display: true, position: 'bottom' }, plugins: {labels: {anchor:'end',render: 'value'}}, scales: { x: { stacked: true }, y: { stacked: true } } } });
  */
        //        var chart = new Chart($chart, { type: 'bar', data: { labels: tehLabels, datasets: dataSets }, options: { plugins: { datalabels: { display: (context) => { return context.dataset.data[context.dataIndex] > 0; }, anchor: 'start', align: 'center' } }, maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, legend: { display: true, position: 'bottom' }, plugins: { labels: { anchor: 'end', render: 'value' } }, scales: { x: { stacked: true }, y: { stacked: true } } }, plugins: [_wa_startup_.ChartDataLabels] });
        if (chartType === 'line') {

            var chart = new Chart($chart, {
                type: 'line',
                data: { labels: tehLabels, datasets: dataSets }, options: {
                    plugins: { legend: { display: false } }, maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, legend: { display: true, position: 'bottom' }, scales: { x: xAxisOptions[0], y: yAxisOptions[0] }
                }
            });
        }
        else {
            var chart = new Chart($chart, {
                type: 'bar',
                plugins: [_wa_startup_.ChartDataLabels, topLabels], data: { labels: tehLabels, datasets: dataSets }, options: {
                    legend: { display: false },
                    plugins: {
                        legend: { display: false }, datalabels: { align: 'start', anchor: 'end', color: 'black', display: function (context) { return false;/* context.dataset.data[context.dataIndex] > 0;*/ }, font: { weight: 'bold' }, formatter: Math.round }
                    },
                    maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, scales: { x: xAxisOptions[0], y: yAxisOptions[0] }
                }
            });

        }
        //        var chart = new Chart($chart, { type: 'bar', data: { labels: tehLabels, datasets: dataSets }, options: { maintainAspectRatio: false, tooltips: { mode: mode, intersect: intersect }, hover: { mode: mode, intersect: intersect }, legend: { display: true, position: 'bottom' } }, scales: { x: { stacked: true }, y: { stacked: true } }, plugins: { datalabels: { display: (context) => { return context.dataset.data[context.dataIndex] > 0; }, anchor: 'start', align: 'center' } } });

    }


    var clearFields = function (e) {
        var fieldClass = $(e.currentTarget).data('clear-param');
        var $inputs = $(e.currentTarget).closest('form').find('input:visible.'+fieldClass);
        $inputs.val('');
        var $selects = $(e.currentTarget).closest('form').find('.select2-single.' + fieldClass + ',.select2-multiple.' + fieldClass);
        $selects.val(null).trigger('change');
    };
    $(".js-search-clear", this).on('click', function (e) {
        clearFields(e);
    });
    $(".js-search-clearall", this).on('click', function (e) {
        var $inputs = $(e.currentTarget).closest('form').find('input:visible');
        $inputs.val('');
        var $selects = $(e.currentTarget).closest('form').find('.select2-single,.select2-multiple');
        $selects.val(null).trigger('change');
    });

/*    $(".js-search-clearall", this).on('click', function (e) {
        var $inputs = $(e.currentTarget).closest('form').find('input:visible');
        $inputs.val('');
        var $selects = $(e.currentTarget).closest('form').find('.select2-single,.select2-multiple');
        $selects.val(null).trigger('change');
    });*/
    $(".js-dashboard2-add-project-chart", this).on('click', function (e) {
        var url = $(e.currentTarget).data("url");

        //        var data = { "Email": email, "ForgotPassword": forgotPassword};

        var data = {
            "NumberOfCharts": $(e.currentTarget).closest('.wa-dashboard2-page').find('input#NumberOfCharts').val()
        };


        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);
    });
    $(".js-dashboard1-add-multiple-chart", this).on('click', function (e) {
        var url = $(e.currentTarget).data("url");

        //        var data = { "Email": email, "ForgotPassword": forgotPassword};

        var data = {
            "NumberOfCharts": $(e.currentTarget).closest('.wa-dashboard1-page').find('input#NumberOfCharts').val()
        };


        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);
    });

    $(".js-integerOnly", this).on('keydown', function (e) {

        if (e.ctrlKey // (A)
            || e.altKey // (A)
            || typeof e.key !== 'string' // (B)
            || e.key.length !== 1) { // (C)
            return;
        }

        var digitsOnlyRegExp = /\d/
        if (digitsOnlyRegExp.test(e.key))
            return;
        e.preventDefault();
    });

    $(".js-decimal", this).on('keydown', function (e) {

        if (e.ctrlKey // (A)
            || e.altKey // (A)
            || typeof e.key !== 'string' // (B)
            || e.key.length !== 1) { // (C)
            return;
        }

        var newValue = '';
        if (e.target.selectionStart != undefined) {
            var startPos = e.target.selectionStart;
            var endPos = e.target.selectionEnd;
            var selectedText = $(e.currentTarget).val().substring(startPos, endPos);
            var $text = $(e.currentTarget).val();
            // alert("Hello, you've selected " + selectedText);
            newValue = $text.substring(0, startPos) + e.key + $text.substring(endPos);
            //  alert("Hello, new value " + newValue);
        }

        var $whatDecimalSeparator = function () {
            var n = 1.1;
            n = n.toLocaleString().substring(1, 2);
            return n;
        }
        var digitsOnlyRegExp = /^(-?)\d*$/
        var digitsAndPeriodRegExp = /^(-?)\d+,$/
        var digitsPerioddigitsRegExp = /^(-?)\d+,(\d+)?$/
        if ($whatDecimalSeparator() === '.') {
            digitsAndPeriodRegExp = /^(-?)\d+\.$/
            digitsPerioddigitsRegExp = /^(-?)\d+\.(\d+)?$/
        }
        if (newValue === null || newValue === "")
            return;
        if (digitsOnlyRegExp.test(newValue) || digitsAndPeriodRegExp.test(newValue) || digitsPerioddigitsRegExp.test(newValue))
            return;

        e.preventDefault();
    });
    $(".js-positivedecimal", this).on('keydown', function (e) {


        if (e.ctrlKey // (A)
            || e.altKey // (A)
            || typeof e.key !== 'string' // (B)
            || e.key.length !== 1) { // (C)
            return;
        }

        var newValue = '';
        if (e.target.selectionStart != undefined) {
            var startPos = e.target.selectionStart;
            var endPos = e.target.selectionEnd;
            var selectedText = $(e.currentTarget).val().substring(startPos, endPos);
            var $text = $(e.currentTarget).val();
            // alert("Hello, you've selected " + selectedText);
            newValue = $text.substring(0, startPos) + e.key + $text.substring(endPos);
            //  alert("Hello, new value " + newValue);
        }

        var $whatDecimalSeparator = function () {
            var n = 1.1;
            n = n.toLocaleString().substring(1, 2);
            return n;
        }
        var digitsOnlyRegExp = /^\d+$/
        var digitsAndPeriodRegExp = /^\d+,$/
        var digitsPerioddigitsRegExp = /^\d+,(\d+)?$/
        if ($whatDecimalSeparator() === '.') {
            digitsAndPeriodRegExp = /^\d+\.$/
            digitsPerioddigitsRegExp = /^\d+\.(\d+)?$/
        }
        if (newValue === null || newValue === "")
            return;
        if (digitsOnlyRegExp.test(newValue) || digitsAndPeriodRegExp.test(newValue) || digitsPerioddigitsRegExp.test(newValue))
            return;

        e.preventDefault();
        
    });

    /*$(".js-decimal", this).on('keydown', function (e) {

        var $whatDecimalSeparator = function () {
            var n = 1.1;
            n = n.toLocaleString().substring(1, 2);
            return n;
        }
        var negativeDigitRegExp = /-|\d/
        var digitPeriodRegExp = /\d|,/

        var digitRegExp = /\d/

        var digitsOnlyRegExp = /^\d+$/
        var negativeDigitsOnlyRegExp = /^-\d+$/

        var digitsAndPeriodRegExp = /^\d+,/
        var negativeDigitsAndPeriodRegExp = /^-\d+,/

        var digitsPerioddigitsRegExp = /^\d+,(\d+)?$/
        var negativeDigitsPerioddigitsRegExp = /^-\d+,(\d+)?$/

        if ($whatDecimalSeparator() === '.') {
            digitPeriodRegExp = /\d|\./
            digitsPerioddigitsRegExp = /^\d+\.(\d+)?$/
            negativeDigitsPerioddigitsRegExp = /^-\d+\.(\d+)?$/
        }

        var $value = $(e.currentTarget).val();
        if (e.ctrlKey // (A)
            || e.altKey // (A)
            || typeof e.key !== 'string' // (B)
            || e.key.length !== 1) { // (C)
            return;
        }
        if ($value !== null && $value !== "") {
            if (digitsOnlyRegExp.test($value) || negativeDigitsOnlyRegExp.test($value)) {
                if (digitPeriodRegExp.test(e.key))
                    return;
                e.preventDefault();
            }
            else if (digitsAndPeriodRegExp.test($value) || digitsPerioddigitsRegExp.test($value)
                || negativeDigitsAndPeriodRegExp.test($value) || negativeDigitsPerioddigitsRegExp.test($value)) {
                if (!digitRegExp.test(e.key))
                    e.preventDefault();
            }
        }
        else if (!digitPeriodRegExp.test(e.key) && !negativeDigitRegExp.test(e.key))
            e.preventDefault();

        return;

    });
    $(".js-positivedecimal", this).on('input', function (e) {

        var $whatDecimalSeparator = function () {
            var n = 1.1;
            n = n.toLocaleString().substring(1, 2);
            return n;
        }

        var digitPeriodRegExp = /\d|,/
        var digitRegExp = /\d/
        var digitsOnlyRegExp = /^\d+$/
        var digitsAndPeriodRegExp = /^\d+,/
        var digitsPerioddigitsRegExp = /^\d+,(\d+)?$/
        if ($whatDecimalSeparator() === '.') {
            digitPeriodRegExp = /\d|\./
            digitsPerioddigitsRegExp = /^\d+\.(\d+)?$/
        }

        var $value = $(e.currentTarget).val();
        if (e.ctrlKey // (A)
            || e.altKey // (A)
            || typeof e.key !== 'string' // (B)
            || e.key.length !== 1) { // (C)
            return;
        }
        if ($value !== null && $value !== "") {
            if (digitsOnlyRegExp.test($value)) {
                if (digitPeriodRegExp.test(e.key))
                    return;
                e.preventDefault();
            }
            else if (digitsAndPeriodRegExp.test($value) || digitsPerioddigitsRegExp.test($value)) {
                if (!digitRegExp.test(e.key))
                    e.preventDefault();
            }
        }
        else if (!digitPeriodRegExp.test(e.key))
            e.preventDefault();

        return;

    });
    $(".js-positivedecimalOLD", this).on('keydown', function (e) {

        var $whatDecimalSeparator  = function () {
            var n = 1.1;
            n = n.toLocaleString().substring(1, 2);
            return n;
        }

        var digitPeriodRegExp = /\d|,/
        var digitRegExp = /\d/
        var digitsOnlyRegExp = /^\d+$/
        var digitsAndPeriodRegExp = /^\d+,/
        var digitsPerioddigitsRegExp = /^\d+,(\d+)?$/
        if ($whatDecimalSeparator() === '.') {
            digitPeriodRegExp = /\d|\./
            digitsPerioddigitsRegExp = /^\d+\.(\d+)?$/
        }

        var $value = $(e.currentTarget).val();
        if (e.ctrlKey // (A)
            || e.altKey // (A)
            || typeof e.key !== 'string' // (B)
            || e.key.length !== 1) { // (C)
            return;
        }
        if ($value !== null && $value !== "") {
            if (digitsOnlyRegExp.test($value)) {
                if (digitPeriodRegExp.test(e.key))
                    return;
                e.preventDefault();
            }
            else if (digitsAndPeriodRegExp.test($value) || digitsPerioddigitsRegExp.test($value)) {
                if (!digitRegExp.test(e.key))
                    e.preventDefault();
            }
        }
        else if (!digitPeriodRegExp.test(e.key))
            e.preventDefault();

        return;
        
    });*/
    $(".js-dashboard1-add-single-chart", this).on('click', function (e) {
        var url = $(e.currentTarget).data("url");

        //        var data = { "Email": email, "ForgotPassword": forgotPassword};
        
        var data = {
            "NumberOfCharts": $(e.currentTarget).closest('.wa-dashboard1-page').find('input#NumberOfCharts').val() };


        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);
    });


    
    $(".js-dashboard1-close-statistics", this).on('click', function (e) {
        
        var $card = $(event.currentTarget).closest('.card').closest('.statistic').remove();
    });
    $(".js-dashboard2-close-statistics", this).on('click', function (e) {

        var $card = $(event.currentTarget).closest('.card').closest('.statistic').remove();
    });


    $(function () {
        var $alreadyLoaded = $(document).find(".wa-content-page.wa-dashboard2-page input#chartLoaded");
        if ($alreadyLoaded !== undefined && $alreadyLoaded.length > 0 && $alreadyLoaded.val() ==='loaded') {
            return;
        }
        $(document).ready(function () {
            var $form = $("div.wa-dashboard2-page form#TehOne",this);
            if ($form.length == 0)
                return;
            $(document).unbind('ready');
                $form.find("canvas#project-chart").remove();
                $form.find("div.project-chart").append('<canvas id="project-chart" class="animated fadeIn" height="200"></canvas>');
                var $chartID = $form.find('#project-chart');

                var $processData = function (data) {
                    let tehLabels = [];
                    let dataAr = [];
                    let lineLabels = [];
                    let types = [];
                    var $notReviewedColor = $('#wa-colors .dashboard1-notrv').data('color-value');
                    var $failedRVColor = $('#wa-colors .dashboard1-frv').data('color-value');
                    var $successRVColor = $('#wa-colors .dashboard1-srv').data('color-value');
                    var $totalColor = $('#wa-colors .dashboard1-total').data('color-value');
                    var $regeneratedColor = $('#wa-colors .dashboard1-regenerated').data('color-value');
                    let lineColors = [$notReviewedColor, $failedRVColor, $successRVColor, $regeneratedColor];
                    processStatistics(data, tehLabels, dataAr, lineLabels, types);
                    //$tehfunc();
                    generate2DGraph('bar', types, $chartID, lineColors, tehLabels, dataAr, lineLabels, false);
                    var tehInput = $(document).find(".wa-content-page.wa-dashboard2-page input#chartLoaded");
                    tehInput.val('loaded');
                }
                //var $form = $(e.currentTarget).closest('form');
                var $formDates = $form.find(".date-range");
                $formDates.val(moment().format('YYYY/MM/DD') + ' - ' + moment().format('YYYY/MM/DD'));
                var data = $form.serializeArray();
                var $container = $form.closest('.wa-dashboard2-page');
                var options = {
                    url: $form.attr('action'),
                    data: $.param(data),
                    loaderTarget: $container,
                    processJSON: $processData
                };


            $.fn.postAjax(options);
        


        });
    }());

    
    $(".js-dashboard2-update", this).on('click', function (e) {
        $form = $(e.currentTarget).closest('form');
        $form.find("canvas#project-chart").remove();
        $form.find("div.project-chart").append('<canvas id="project-chart" class="animated fadeIn" height="200"></canvas>');
        var $chartID = $form.find('#project-chart');

        var $processData = function (data) {
            let tehLabels = [];
            let dataAr = [];
            let lineLabels = [];
            let types = [];
            var $notReviewedColor = $('#wa-colors .dashboard1-notrv').data('color-value');
            var $failedRVColor = $('#wa-colors .dashboard1-frv').data('color-value');
            var $successRVColor = $('#wa-colors .dashboard1-srv').data('color-value');
            var $totalColor = $('#wa-colors .dashboard1-total').data('color-value');
            var $regeneratedColor = $('#wa-colors .dashboard1-regenerated').data('color-value');
            let lineColors = [$notReviewedColor, $failedRVColor, $successRVColor, $regeneratedColor];
            processStatistics(data, tehLabels, dataAr, lineLabels, types);
            //$tehfunc();
            generate2DGraph('bar', types, $chartID, lineColors, tehLabels, dataAr, lineLabels, false);
        }
        var $form = $(e.currentTarget).closest('form');
        if (!$form.validate().form()) {
            e.preventDefault();
            return;
        }

        var data = $form.serializeArray();
        var $container = $(e.target).closest('.wa-dashboard2-page');
        var options = {
            url: $form.attr('action'),
            data: $.param(data),
            loaderTarget: $container,
            processJSON: $processData
        };
        

        $.fn.postAjax(options);

    });
    $(".js-dashboard1-update-multiple", this).on('click', function (e) {
        $form = $(e.currentTarget).closest('form');
        $form.find("canvas#multiple-technicians-chart").remove();
        $form.find("div.multiple-technicians-chart").append('<canvas id="multiple-technicians-chart" class="animated fadeIn" height="200"></canvas>');
        var $chartID = $form.find('#multiple-technicians-chart');

        var $processData = function (data) {
            let tehLabels = [];
            let dataAr = [];
            let lineLabels = [];
            let types = [];
            var $notReviewedColor = $('#wa-colors .dashboard1-notrv').data('color-value');
            var $failedRVColor = $('#wa-colors .dashboard1-frv').data('color-value');
            var $successRVColor = $('#wa-colors .dashboard1-srv').data('color-value');
            var $totalColor = $('#wa-colors .dashboard1-total').data('color-value');
            var $regeneratedColor = $('#wa-colors .dashboard1-regenerated').data('color-value');
            let lineColors = [$notReviewedColor, $failedRVColor, $successRVColor, $regeneratedColor];
            processStatistics(data, tehLabels, dataAr, lineLabels,types);
            //$tehfunc();
            generate2DGraph('bar',types,$chartID, lineColors, tehLabels, dataAr, lineLabels, false);
        }
        var $form = $(e.currentTarget).closest('form');
        if (!$form.validate().form()) {
            e.preventDefault();
            return;
        }

        var data = $form.serializeArray();
        var $container = $(e.target).closest('.wa-dashboard1-page');
        var options = {
            url: $form.attr('action'),
            data: $.param(data),
            loaderTarget: $container,
            processJSON: $processData
        };
/*
        var $url = $(event.target).data('url');
        var $weeklevel = $container.find('#AggregationMultiple').val();
        var $technicians = $container.find('#TechnicianUserIds').val();
        var $date = $container.find('#SingleDate').val();
        var $tehData = { TechnicianUserIds: $technicians, SingleDate: $date, AggregationMultiple: $weeklevel };
        
        var $form = $(event.target).closest('form');
        var options = {
            url: $url,
            loaderTarget: $container,
            processJSON: $processData,
            data: $tehData
        };*/

        $.fn.postAjax(options);

    });
    $(".js-dashboard1-update-single", this).on('click', function (e) {
        $form = $(e.currentTarget).closest('form');
        $form.find("canvas#single-technicians-chart").remove();
        $form.find("div.single-technicians-chart").append('<canvas id="single-technicians-chart" class="animated fadeIn" height="200"></canvas>');
        var $chartID = $form.find('#single-technicians-chart');
        var $processData = function (data) {
            let tehLabels = [];
            let dataAr = [];
            let lineLabels = [];
            let types = [];
            var $notReviewedColor = $('#wa-colors .dashboard1-notrv').data('color-value');
            var $failedRVColor = $('#wa-colors .dashboard1-frv').data('color-value');
            var $successRVColor = $('#wa-colors .dashboard1-srv').data('color-value');
            var $totalColor = $('#wa-colors .dashboard1-total').data('color-value');
            var $regeneratedColor = $('#wa-colors .dashboard1-regenerated').data('color-value');
            let lineColors = [$totalColor, $notReviewedColor, $failedRVColor, $successRVColor, $regeneratedColor];

            processStatistics(data, tehLabels, dataAr, lineLabels,types);
            generate2DGraph('line',types,$chartID, lineColors, tehLabels, dataAr, lineLabels,false);
        }
        var $form = $(e.currentTarget).closest('form');
        if (!$form.validate().form()) {
            e.preventDefault();
            return;
        }
        /*var $url = $(event.target).data('url');
        var $container = $(event.target).closest('.wa-dashboard1-page');
        var $weeklevel = $container.find('#AggregationSingle').val();
        var $technician = $container.find('#TechnicianUserId').val();
        var $fromDate = $container.find('#FromDate').val();
        var $toDate = $container.find('#ToDate').val();
        var $tehData = { TechnicianUserId: $technician, FromDate: $fromDate, ToDate: $toDate, AggregationSingle: $weeklevel };

        var $form = $(event.target).closest('form');
        var options = {
            url: $url,
            loaderTarget: $container,
            processJSON: $processData,
            data: $tehData
        };*/
        var data = $form.serializeArray();
        var $container = $(e.target).closest('.wa-dashboard1-page');
        var options = {
            url: $form.attr('action'),
            data: $.param(data),
            loaderTarget: $container,
            processJSON: $processData
        };
        $.fn.postAjax(options);

    });
  
    $(".wa-project-checklist-table", this).on('init.dt', function (e) {
        var $table = $(e.target);
        var $dataTable = $table.DataTable();
        var $tehDiv = $(e.target).closest("fieldset");
        var data = {
        };
        var $idx;
        var $processCheckList = function (data) {

            var numberOfRows = $dataTable.rows().count();
            var sortOrder = 1;
            if (numberOfRows > 0) {
                var lastRowData = $dataTable.row(numberOfRows - 1).data();
                sortOrder = lastRowData[2] + 1;
            }
            $dataTable.rows().deselect();
            data.forEach(function ($text) { $dataTable.row.add([0, $text, sortOrder]); sortOrder = sortOrder + 1 });
            $dataTable.draw(false);
        };
        var $copyProjectCheckListFunc = function (event) {
            var $container = $(event.target).closest('.js-project-copy-checklist-container');
            var $select = $container.find('.js-project-copy-checklist-projectid');
            var $projectId = $select.val();
            var $url = $(event.target).data('url');
            var $tehData = { ProjectId: $projectId };
            var $form = $(event.target).closest('form');
            var options = {
                url: $url,
                loaderTarget: $form.parents('.wa-content-page'),
                processJSON: $processCheckList,
                data: $tehData
            };

            $.fn.postAjax(options);
        };

        $tehDiv.off('click', 'button.js-project-copy-checklist');
        $tehDiv.on('click', 'button.js-project-copy-checklist', $copyProjectCheckListFunc);


        var $Edit = function () {
            var $httml = $tehDiv.find(".edit-checklist-item-dialog");
            var $tehHtml = $httml.html();
            var $currentdialog = $httml.closest('.ui-dialog-content');
            var $maxWidth = $currentdialog.dialog("option", "maxWidth");
            var $width = $currentdialog.dialog("option", "width");
            var $dialog = $tehDiv.showLocalModalDialog({ html: $tehHtml });
            $width = $width - 100;
            $maxWidth = $maxWidth - 100;
            $dialog.dialog("option", "maxWidth", $maxWidth);
            $dialog.dialog("option", "width", $width);
            $maxWidth = $dialog.dialog("option", "maxWidth");
            $width = $dialog.dialog("option", "width");

            var $tt = $dialog.find(".js-project-checklistitem-text");
            var $tt2 = $httml.find(".js-project-checklistitem-text");
            $tt.val($tt2.val());


            var $tehfunc = function (event) {
                //e.preventDefault();
                var $form = $(event.target).closest('form');
                //alert('ahaa ' + $form.find(".js-project-checklistitem-text").val());
                if (!$form.validate().valid()) {
                    e.preventDefault();
                    return;
                }
                var $text = $(event.target).closest('fieldset').find('.js-project-checklistitem-text').val();
                //var $idx = $dataTable.row().index();
                $dataTable.cell($idx, 1).data($text).draw(false);
                //$dataTable.row.add(['0', $text, 'True']).draw(false);
                $dataTable.rows().deselect();
                $dialog.dialog('close');


            };





            //$tehDiv.off('click', 'button.wa-project-checklist-dialog-action-button', $tehfunc);
            $tehDiv.off('click', 'button.wa-project-checklist-dialog-action-button');
            $tehDiv.on('click', 'button.wa-project-checklist-dialog-action-button', $tehfunc);




            $tehDiv.on('change', '.js-project-checklistitem-text', function (event) {
                //e.preventDefault();'
                var $text = $(this).val();
                if ($.trim($text) === "")
                    $(event.target).closest('fieldset').find('button.wa-project-checklist-dialog-action-button').prop('disabled', true);
                else
                    $(event.target).closest('fieldset').find('button.wa-project-checklist-dialog-action-button').prop('disabled', false);
            });
        };


        var editButtonOptions = {
            Edit: $Edit,
            idColumnSelector: 'Id:name',
            textColumnSelector: 'Text:name'
            //readonly: $('#wa-has-manage-type-data-authorization').length === 0
        };
        $.fn.addLocalEditButton($table, $dataTable, editButtonOptions);



        var $Delete = function () {
            var rows = $dataTable
                .rows('.selected')
                .remove()
                .draw();
            //$dataTable.row().remove().draw();
            $dataTable.rows().deselect();
        };

        var deleteButtonOptions = {
            Delete: $Delete,
            idColumnSelector: 'Id:name',
            textColumnSelector: 'Text:name'
            //readonly: $('#wa-has-manage-type-data-authorization').length === 0
        };
        $.fn.addLocalDeleteButton($table, $dataTable, deleteButtonOptions);


        var $moveUp = function (event) {
            var index = $dataTable.rows({ selected: true }).indexes()[0];
            if (index === 0)
                return;

            var rowToMoveUp = $dataTable.row(index).data();
            var rowData = $dataTable.row(index - 1).data();
            rowData[2]++;
            $dataTable.row(index).data(rowData);
            rowToMoveUp[2]--;
            $dataTable.row(index - 1).data(rowToMoveUp);
            $dataTable.draw(true);
            $dataTable.rows().deselect();
            $dataTable.row(':eq(' + (index - 1) + ')', { page: 'current' }).select();
            /*var rowToMoveUp = $dataTable.row($idx).data();
            for (var i = $idx; i > 0; --i) {
                var rowData = $dataTable.row(i - 1).data();
                rowData[2]++;
                $dataTable.row(i).data(rowData);
            }
            rowToMoveUp[2] = 1;
            $dataTable.row(0).data(rowToMoveUp);
            $dataTable.order([2, 'asc']).draw(true);*/

            //$dataTable.rows().select($idx-1);
            //            $dataTable.order([2, 'asc']).draw(true); 

            // Move through each row, bump the previous row up one:
            /*
            var rowToMoveUp = $dataTable.row($idx).data();
            for (var i = $idx; i > 0; --i) {
                var rowData = $dataTable.row(i - 1).data();
                rowData[2]++;
                $dataTable.row(i).data(rowData);
            }
            rowToMoveUp[0] = 1;
            $dataTable.row(0).data(rowToMoveUp);
            $dataTable.draw(true);*/
        };
        var moveUpButtonOptions = {
            DoProcess: $moveUp,
            idColumnSelector: 'Id:name',
            textColumnSelector: 'Text:name'
            //readonly: $('#wa-has-manage-type-data-authorization').length === 0
        };
        _wa_datatables_.addLocalButton($table, $dataTable, moveUpButtonOptions, '.wa-projectchecklist-move-up');
        var $moveDown = function (event) {
            var numberOfRows = $dataTable.rows().count();
            var index = $dataTable.rows({ selected: true }).indexes()[0];
            //var index = $dataTable.row().index();
            if (index === (numberOfRows - 1))
                return;

            var rowToMoveDown = $dataTable.row(index).data();
            var rowData = $dataTable.row(index + 1).data();
            rowData[2]--;
            $dataTable.row(index).data(rowData);
            rowToMoveDown[2]++;
            $dataTable.row(index + 1).data(rowToMoveDown);
            $dataTable.draw(true);
            $dataTable.rows().deselect();
            $dataTable.row(':eq(' + (index + 1) + ')', { page: 'current' }).select();

        };
        var moveDownButtonOptions = {
            DoProcess: $moveDown,
            idColumnSelector: 'Id:name',
            textColumnSelector: 'Text:name'
            //readonly: $('#wa-has-manage-type-data-authorization').length === 0
        };
        _wa_datatables_.addLocalButton($table, $dataTable, moveDownButtonOptions, '.wa-projectchecklist-move-down');

        $(e.target).closest('div.tab-pane').on('click', '.js-project-add-checklistitem', function (event) {
            //e.preventDefault();'
            var numberOfRows = $dataTable.rows().count();
            var sortOrder = 1;
            if (numberOfRows > 0) {
                var lastRowData = $dataTable.row(numberOfRows - 1).data();
                sortOrder = lastRowData[2] + 1;
            }

            var $text = $(event.target).closest('fieldset').find('.js-project-checklist-add-text').val();
            $dataTable.row.add([0, $text, sortOrder]).draw(false);
            $dataTable.rows().deselect();
        });



        $(e.target).closest('div.tab-pane').on('change', '.js-project-checklist-add-text', function (event) {
            //e.preventDefault();'
            var $text = $(this).val();
            if ($.trim($text) === "")
                $(event.target).closest('fieldset').find('.js-project-add-checklistitem').prop('disabled', true);
            else
                $(event.target).closest('fieldset').find('.js-project-add-checklistitem').prop('disabled', false);
        });


        $dataTable.on('select.dt', function (e, dt, type, indexes) {
            var data = dt.rows(indexes).data();
            $idx = indexes;
            var text = data[0][1];
            var $tehDiv = $(e.target).closest("fieldset");
            $tehDiv.find(".edit-checklist-item-dialog").find(".js-project-checklistitem-text").val(text);
            //$tehDiv.find(".wa-project-checklist-dialog-moveup-button").prop('disabled', false);
            //$tehDiv.find(".wa-project-checklist-dialog-movedown-button").prop('disabled', false);
            // $dialog.closest('form').validate();
        });
        /* $dataTable.on('deselect.dt', function (e, dt, type, indexes) {
             var $tehDiv = $(e.target).closest("fieldset");
             //$tehDiv.find(".wa-project-checklist-dialog-moveup-button").prop('disabled', true);
             //$tehDiv.find(".wa-project-checklist-dialog-movedown-button").prop('disabled', true);
             // $dialog.closest('form').validate();
         });*/
        $dataTable.order([2, 'asc']).draw(false);

    });




    //workteam
    $(".wa-workteam-user-table", this).on('init.dt', function (e) {
        var $table = $(e.target);
        var $dataTable = $table.DataTable();
        var $tehDiv = $(e.target).closest("fieldset");
        var data = {
        };
        var $idx;
        var $addUserFunc = function (event) {
            var $container = $(event.target).closest('.js-workteam-adduser-container');
            var $select = $container.find('.js-workteam-adduser-userid');
            var $userId = $select.val();
            var $userName = $select.find('option:selected').text();

            var $found = false;
            $dataTable.rows().every(function (index, element) {

                var rowdata = this.data();
                if (rowdata[1] === $userId)
                    $found = true;
                /* this shows correct row data,
                 * 
                        but not whether first column checkbox is checked by user */
            });
            if ($found)
                return;
            $dataTable.row.add([0, $userId, $userName]).draw(false);
            $dataTable.draw(false);
            $dataTable.rows().deselect();



        };

        $tehDiv.off('click', 'button.js-workteam-adduser');
        $tehDiv.on('click', 'button.js-workteam-adduser', $addUserFunc);
        var $Delete = function () {
            var rows = $dataTable
                .rows('.selected')
                .remove()
                .draw();
            //$dataTable.row().remove().draw();
            $dataTable.rows().deselect();
        };

        var deleteButtonOptions = {
            Delete: $Delete,
            idColumnSelector: 'Id:name',
            textColumnSelector: 'Text:name'
            //readonly: $('#wa-has-manage-type-data-authorization').length === 0
        };
        $.fn.addLocalDeleteButton($table, $dataTable, deleteButtonOptions);

        $dataTable.order([1, 'asc']).draw(false);

    });
    //workteam



    //$("#wa-main-content-container").on('init.dt', "#wa-search-workorder-result-container", function (e) {
    $("#wa-search-workorder-result-container", this).on('init.dt', function (e) {
        
        var $table = $(e.target);
        var $url = $table.closest('div.wa-workorder-assign-page').data("protocol-url");
        let protocolExport = $table.data('protocol-export-enabled') === 'True';
        if (protocolExport) {
            var $dataTable = $table.DataTable({ "bRetrieve": true });
            var $loaderTargetSelector = $table.closest(".wa-content-page");
            var data = {
                IgnoreResults: 'True'
            };


            var editButtonOptions = {
                url: $url,
                loaderTargetSelector: '#wa-search-workorder-result-container',
                idColumnSelector: 'workorderId:name',
                data: data,
                //readonly: $('#wa-has-manage-type-data-authorization').length === 0
            };
            _wa_datatables_.addExportButton('.wa-download-protocol-button', $table, $dataTable, editButtonOptions);
        }
    });
    
    $("#wa-workorder-assign-search-result-container", this).on('init.dt', function (e) {
        var $table = $(e.target);
        var $url = $table.closest('div.wa-workorder-assign-page').data("protocol-url");
        let protocolExport = $table.data('protocol-export-enabled') === 'True';
        if (protocolExport) {
            var $dataTable = $table.DataTable({ "bRetrieve": true });
            var $loaderTargetSelector = $table.closest(".wa-content-page");
            var data = {
                IgnoreResults: 'True'
            };


            var editButtonOptions = {
                url: $url,
                loaderTargetSelector: '#wa-workorder-assign-search-result-container',
                idColumnSelector: 'workorderId:name',
                data: data,
                //readonly: $('#wa-has-manage-type-data-authorization').length === 0
            };
            _wa_datatables_.addExportButton('.wa-download-protocol-button', $table, $dataTable, editButtonOptions);
        }
    });
    $(".wa-workorder-extrawork-table", this).on('init.dt', function (e) {
        var $table = $(e.target);
        var $dataTable = $table.DataTable();
        var $loaderTargetSelector = $table.closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var $containingDiv = $table.closest('div.container-id');
        var $containerId = $containingDiv.attr('id');
        var workorderId = $containingDiv.data('workorder-id');
        var fieldVisitEventId = $containingDiv.data('field-visit-event-id');

        var data = {
            contentPage: $contentPage,
            containerId: $containerId,
            WorkorderId: workorderId,
            FieldVisitEventId: fieldVisitEventId
        };


        var editButtonOptions = {
            url: $('#wa-extrawork-editor-dialog-url').attr('href'),
            loaderTargetSelector: 'div.wa-type-data-page',
            idColumnSelector: 'fieldVisitActivityId:name',
            data: data,
            contentInstruction: { replaceInnerFor: '#wa-type-data-search-result-container' }//,
            //readonly: $('#wa-has-manage-type-data-authorization').length === 0
        };
        $.fn.addEditButton($table, $dataTable, editButtonOptions);
    });

    




    $(this).webAppDataTable(options);



    
    $('.js-edit-verification-review', this).on('click',
        function (e) {
            var $form = $(e.currentTarget).closest("form");
            if ($(this).is(":checked")) {
                $form.find('.js-attest-verification-protocol').removeClass('d-none');
                $form.find('.js-attest-recreate-verification-protocol').removeClass('d-none');
                $form.find('.js-failattest-verification-protocol').removeClass('d-none');
            }
            else {
                $form.find('.js-attest-verification-protocol').addClass('d-none');
                $form.find('.js-attest-recreate-verification-protocol').addClass('d-none');
                $form.find('.js-failattest-verification-protocol').addClass('d-none');
            }
        });


    $('.js-workorder-create-extrawork', this).on('click', function (event) {

        event.preventDefault();
        var $form = $(event.currentTarget).closest('form');
        if (!$form.validate().form()) {
            event.preventDefault();
            return;
        }
        var $url = $form.attr('action');
        var data = $form.serializeArray();

        var $contentPage = $form.closest(".wa-content-page").data("workorder-content-page");
        //var $containerId = $(this).data('container-id');
        $form.find("#contentPage").val($contentPage);
        //$form.find("#containerId").val($containerId);
        //var e11 = $form.find("#containerId");
        //var e22 = $form.find("#contentPage");

        //var e1 = e11.val();
        //var e2 = e22.val();
        $form.submit();



    });



    /*assign
    $("#wa-main-content-container").on('change', '.js-assign-technician' , function (e) {
    //$('js-assign-technician', this).on('change', function (e) {
        var $parentContainer = $(e.target).closest('div.row');
        var $technicians = $parentContainer.find('.js-assign-workteam');
        $technicians.val(null).trigger("change");
    });
    */

    function onChangeAcualLoadMissing(e) {
        var actualLoadMissing = $(this).is(":checked") 
        if (actualLoadMissing) {
            //$(this).closest('form').find('.js-display-current').addClass('hidden');
            $(this).closest('form').find('.js-display-current').addClass('d-none');
            $(this).closest('form').find('.js-enable-current').prop('readonly', true);
            //$(this).closest('form').find('.js-enable-current').val("0");
            
        }
        else {
            //$(this).closest('form').find('.js-display-current').removeClass('hidden');
            $(this).closest('form').find('.js-display-current').removeClass('d-none');
            $(this).closest('form').find('.js-enable-current').prop('readonly', false);
        }
    };

    function onChangeIsMeasuredCTBurden(e) {
        var arrayOfMeasured = $(this).closest('.tab-content').find('.js-burden-measured');
        var isMeasured = false;
        arrayOfMeasured.each(function () {
            isMeasured = isMeasured || $(this).is(":checked") && !$(this).closest("fieldset").find(".js-ct-cable-section-notinuse").is(":checked");
        });
        if (isMeasured) {
            $(this).closest('form').find('.js-display-current-voltage').removeClass('d-none');
        }
        else {
            $(this).closest('form').find('.js-display-current-voltage').addClass('d-none');
        }
    };
    function onChangeIsMeasuredVoltageDrop(e) {
        var arrayOfMeasured = $(this).closest('.tab-content').find('.js-update-voltage-cable-ismeasured-voltagedrop');
        var isMeasuredVoltageDrop = false;
        arrayOfMeasured.each(function () {
            isMeasuredVoltageDrop = isMeasuredVoltageDrop || $(this).is(":checked") && !$(this).closest("fieldset").find(".js-vt-cable-section-notinuse").is(":checked");
        });
        if (isMeasuredVoltageDrop) {
            $(this).closest('form').find('.voltage-drop-tab').removeClass('d-none');
            $(this).closest('form').find('.voltage-drop-tab').attr('hidden', false);
        }
        else {
            $(this).closest('form').find('.voltage-drop-tab').addClass('d-none');
            $(this).closest('form').find('.voltage-drop-tab').attr('hidden', true);
        }
    };
    function onChangeFuseSizeType(e) {

        var $fuseSizeTypeId = parseInt($(this).val());
        var $fuseSizeTypeNotInListValue = $('#FuseSizeNotInList').data('value');
        var notInList = $fuseSizeTypeId === $fuseSizeTypeNotInListValue;
        if (notInList)
        {
            $(this).closest('form').find('.js-operatingconditions-fusesize-text').removeClass('d-none');

        }
        else
        {
            $(this).closest('form').find('.js-operatingconditions-fusesize-text').addClass('d-none');
        }
    }
    $(".js-workorder-create-new", this).on('change',
        function () {

            if ($(this).val() === 'true') {
                $(this).closest('form').find('.js-copy-workorder-note').removeClass('d-none');
                $(this).closest('form').find('.js-copy-workorder-note').prop('disabled', false);
            }
            else {
                $(this).closest('form').find('.js-copy-workorder-note').addClass('d-none');
                $(this).closest('form').find('.js-copy-workorder-note').prop('disabled', true);
            }
        });
    $(".js-operatingconditions-fusesize", this).on('change', onChangeFuseSizeType);
    $(".js-ct-cable-section-notinuse", this).on('change', onChangeIsMeasuredCTBurden);
    $(".js-update-cable-burden-current", this).on('change', onChangeIsMeasuredCTBurden);
    $(".js-vt-cable-section-notinuse", this).on('change', onChangeIsMeasuredVoltageDrop);
    $(".js-update-voltage-cable-ismeasured-voltagedrop", this).on('change', onChangeIsMeasuredVoltageDrop);
    $(".js-verification-actualvalues-loadmissing", this).on('change', onChangeAcualLoadMissing);
        //$("#wa-main-content-container").on('ifChanged', '.js-voltagedrop-fuse', onChangeIsMeasuredVoltageDropFuse);
    $(".js-voltagedrop-fuse",this).on('changed',
        function onChangeIsMeasuredVoltageDropFuse(e) {

            if ($(this).is(":checked")) {
                $(this).closest('form').find('.visibility-voltage-drop-fuse').removeClass('d-none');
                $(this).closest('form').find('.visibility-voltage-drop-fuse').attr('hidden', false);
            }
            else {
                $(this).closest('form').find('.visibility-voltage-drop-fuse').addClass('d-none');
                $(this).closest('form').find('.visibility-voltage-drop-fuse').attr('hidden', true);
            }
        });

    $('.js-forgot-password', this).on('click', function (e) {
        //e.preventDefault();'
        var email = $(e.currentTarget).closest('div').find('#Email').val();
        var forgotPassword = $(e.currentTarget).closest('div').find('#ForgotPassword').val();
        $(e.currentTarget).closest('div').find('#ForgotPassword').val('True');
        forgotPassword = $(e.currentTarget).closest('div').find('#ForgotPassword').val();
        var $form = $(e.currentTarget).closest('div').find('form');
        var data = $form.serializeArray();
        var url = $(e.currentTarget).data("url");
//        var data = { "Email": email, "ForgotPassword": forgotPassword};
        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });

    
    
    $('.js-edit-device-control', this).on('click', function (e) {
        //e.preventDefault();'
        var id = $(e.currentTarget).data("device-id");
        var contentPage = $(e.currentTarget).closest("#accordion").data("workorder-content-page");
        var url = $(e.currentTarget).data("url");
        var data = { "id": id, "contentPage": contentPage };
        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });

    $('.js-show-meteruncertainties', this).on('click', function (e) {
        //e.preventDefault();'
        var $workorderId = $(e.currentTarget).data("workorderid");
        var $workorderAttachmentId = $(e.currentTarget).data("workorderattachmentid");
        var $loaderTargetSelector = $(e.currentTarget).closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var url = $(e.currentTarget).data("url");
        var data = [];
        data.push({ name: 'contentPage', value: $contentPage });
        data.push({ name: 'workorderId', value: $workorderId });
        data.push({ name: 'workorderAttachmentId', value: $workorderAttachmentId });
        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });
    $('.js-show-vectordiagram', this).on('click', function (e) {
        //e.preventDefault();'
        var id = $(e.currentTarget).data("device-id");
        var $loaderTargetSelector = $(e.currentTarget).closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var url = $(e.currentTarget).data("url");
        var $form = $(event.currentTarget).closest('form');
        var data = $form.serializeArray();
        data.push({ name: 'contentPage', value: $contentPage });
        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });

    $('.js-close-workorder', this).on('click', function (e) {
        //e.preventDefault();'
        
        var $loaderTargetSelector = $(e.currentTarget).closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var $workorderId = $loaderTargetSelector.data("workorder-id");
        
        var url = $(e.currentTarget).data("url");
        var data = { "id": $workorderId, "contentPage": $contentPage };
        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });
    $('.js-assign-single-wo', this).on('click', function (e) {
        //e.preventDefault();'

        var $loaderTargetSelector = $(e.currentTarget).closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var $workorderId = $loaderTargetSelector.data("workorder-id");

        var url = $(e.currentTarget).data("url");
        var data = { "id": $workorderId, "contentPage": $contentPage };
        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });
    $('.js-recall-single-wo', this).on('click', function (e) {
        //e.preventDefault();'

        var $loaderTargetSelector = $(e.currentTarget).closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var $workorderId = $loaderTargetSelector.data("workorder-id");

        var url = $(e.currentTarget).data("url");
        var data = { "id": $workorderId, "contentPage": $contentPage };
        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });




    



    
    $('.js-add-workorderrestriction', this).on('click', function (e) {
        //e.preventDefault();'

        var $loaderTargetSelector = $(e.currentTarget).closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var $workorderId = $loaderTargetSelector.data("workorder-id");

        var url = $(e.currentTarget).data("url");
        let data = [];
        data.push({ name: 'workorderId', value: $workorderId });
        data.push({ name: 'contentPage', value: $contentPage });

        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."
        };
        $.fn.postAjax(options);

    });
    $('.js-attest-verification-protocol', this).on('click', function (e) {
        //e.preventDefault();'

        var $loaderTargetSelector = $(e.currentTarget).closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var $workorderId = $loaderTargetSelector.data("workorder-id");

        var url = $(e.currentTarget).data("url");
        var $id = $(e.currentTarget).data("id");
        let data = [];
        data.push({ name: 'workorderId', value: $workorderId });
        data.push({ name: 'contentPage', value: $contentPage });
        data.push({ name: 'id', value: $id });

        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."
        };
        $.fn.postAjax(options);

    });
    $('.js-failattest-verification-protocol,.js-attest-recreate-verification-protocol', this).on('click', function (e) {
        //e.preventDefault();'

        var $loaderTargetSelector = $(e.currentTarget).closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var $workorderId = $loaderTargetSelector.data("workorder-id");
        var $id = $(e.currentTarget).data("id");
        var $protocolno = $(e.currentTarget).data("protocolno");
        var url = $(e.currentTarget).data("url");
        var data = {
            "workorderId": $workorderId, "contentPage": $contentPage,
        "restrictionId":$id,"protocolno":$protocolno};
        var options = {
            url: url,
            data: $.param(data),
            loaderTargetSelector: 'div.wa-content-page',
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    });
    
    
    
    $(".wa-workorder-extrawork-table", this).on('init.dt', function (e) {
        var $table = $(e.target);
        var $dataTable = $table.DataTable();
        var $loaderTargetSelector = $table.closest(".wa-content-page");
        var $contentPage = $loaderTargetSelector.data("workorder-content-page");
        var $containingDiv = $table.closest('div.container-id');
        var $containerId = $containingDiv.attr('id');
        var workorderId = $containingDiv.data('workorder-id');
        var fieldVisitEventId = $containingDiv.data('field-visit-event-id');

        var data = {
            contentPage: $contentPage,
            containerId: $containerId,
            WorkorderId: workorderId,
            FieldVisitEventId: fieldVisitEventId
        };


        var editButtonOptions = {
            url: $('#wa-extrawork-editor-dialog-url').attr('href'),
            loaderTargetSelector: 'div.wa-type-data-page',
            idColumnSelector: 'fieldVisitActivityId:name',
            data: data,
            contentInstruction: { replaceInnerFor: '#wa-type-data-search-result-container' }//,
            //readonly: $('#wa-has-manage-type-data-authorization').length === 0
        };
        $.fn.addEditButton($table, $dataTable, editButtonOptions);
    });


    $(".wa-dialog-action-delete-extrawork-button",this).on('click', function (event) {
        event.preventDefault();
        var $form = $(event.target).closest('form');
        if (!$form.validate().form()) {
            event.preventDefault();
            return;
        }
        $form.find("#DeleteExtraWork").val('true');
        $form.find(".wa-table-dialog-action-button").click();
    });


    $('input.wa-input-group-enabler').on('change', function (event) {
        $(event.currentTarget).closest('div.input-group').find('input.form-control').prop('disabled', !event.currentTarget.checked);
    });









  /*  $('input', this).iCheck({
        checkboxClass: 'icheckbox_minimal-blue',
        radioClass: 'iradio_minimal-blue',
        increaseArea: '20%' // optional
    });*/

    $.validator.unobtrusive.parse(this);


    return this;
};

$.fn.formExtensions.defaults = {

};

$(function () {
    (function registerDefaultFormSubmitHandler() {
        $('body').on('submit', 'form', function (e) {
            //var data = {};
            //if ($(this).data('container-id') != null)
            //   data.push({ name: "ContainerId", value: $(this).data('ContentPage') });\

            if ($(e.target).hasClass('no-def-submit'))
                return;
            e.preventDefault();
            var $form = $(e.currentTarget);
            var options = {
                loaderTarget: $form.parents('.wa-content-page'),
                onSuccess: _wa_ajax_.onRequestResponseSuccess
            };
            $form.postAjax(options); // Data and url from form is automatically added by postAjax (unless provided in options)
        });
    }());





    (function registerSaveDialogFormSubmitHandler() {
        $(document).on('click', 'button.wa-table-dialog-action-button', function (event) {
            //e.preventDefault();

            var $form = $(event.target).closest('form');
            if (!$form.validate().form()) {
                e.preventDefault();
                return;
            }
            var data = $form.serializeArray();
            if ($(event.currentTarget).data('content-page') != null)
                data.push({ name: 'contentPage', value: $(event.currentTarget).data('content-page') });

            if ($(event.currentTarget).data('container-id') != null)
                data.push({ name: 'containerId', value: $(event.currentTarget).data('container-id') });

            var loaderMessage = $(event.currentTarget).data('loader-message');

            var options = {
                url: $form.attr('action'),
                data: $.param(data),
                loaderTarget: $('div.wa-content-page'), // TODO Find the closest dialog instead
                loaderMessage: loaderMessage,
                afterSuccess: function () {
                    $form.closest('.ui-dialog-content').dialog('close');
                }
            };
            $.fn.postAjax(options);
        });


    }());




});

export default _wa_formextensions_;
/*var rowToMoveUp = $dataTable.row($idx).data();
for (var i = $idx; i > 0; --i) {
var rowData = $dataTable.row(i - 1).data();
rowData[2]++;
$dataTable.row(i).data(rowData);
}
rowToMoveUp[2] = 1;
$dataTable.row(0).data(rowToMoveUp);
$dataTable.order([2, 'asc']).draw(true);

        $dataTable.rows().select($idx-1);
                    $dataTable.order([2, 'asc']).draw(true);

         Move through each row, bump the previous row up one:

var rowToMoveUp = $dataTable.row($idx).data();
for (var i = $idx; i > 0; --i) {
    var rowData = $dataTable.row(i - 1).data();
    rowData[2]++;
    $dataTable.row(i).data(rowData);
}
rowToMoveUp[0] = 1;
$dataTable.row(0).data(rowToMoveUp);
$dataTable.draw(true);*/