﻿import Map from './webapp.bingmaps';
import _wa_datatables_ from "./webapp.datatables";
import _wa_toast_ from "./webapp.toast";
import _wa_workorder_ from "./webapp.workorder"

var _wa_plan_ = {
  map: null
}

_wa_plan_.getSelectionData = function ($table, $dataTable) {
    var selectedRowIndexes = $dataTable.rows({ selected: true }).indexes();
    var selectedWorkorderIds = $dataTable.cells(selectedRowIndexes, 'workorderId:name').data().toArray();

    var unselectedRowIndexes = $dataTable.rows({ selected: false }).indexes();
    var unselectedWorkorderIds = $dataTable.cells(unselectedRowIndexes, 'workorderId:name').data().toArray();
    
    var data = {
        selectedWorkorderIds: selectedWorkorderIds,
        unselectedWorkorderIds: unselectedWorkorderIds,
        contentInstruction: { replaceInnerFor: '#wa-workorder-plan-search-result-container' }
    };

    return data;
}



_wa_plan_.addOpenButton = function ($table, $dataTable) {
    var $openButton = $('#wa-button-templates .wa-open-workorder-button').clone();

    $openButton.on('click', function () {
        var selectedRowIndexes = $dataTable.rows({ selected: true }).indexes();
        var selectedWorkorderIds = $dataTable.cells(selectedRowIndexes, 'workorderId:name').data().toArray();
   
        _wa_workorder_.load(selectedWorkorderIds);
    });

    _wa_datatables_.addToolbarItem($table, $openButton);

}

// Attaches event invoked after search result table is displayed
$('#wa-main-content-container').on('init.dt', '#wa-workorder-plan-search-result-table' , function (e) {
    var $table = $(e.target);
    var $dataTable = $table.DataTable();

/*    if ($('#wa-has-manage-workorder-assign-authorization').length > 0)
    {
        _wa_assign_.addAssignButton($table, $dataTable);
        _wa_assign_.addRecallButton($table, $dataTable);
    }
    */
    _wa_datatables_.addSelectAllButton($table, $dataTable);
    _wa_plan_.addOpenButton($table, $dataTable);
    //_wa_plan_.addRefreshButton($table, $dataTable);

    var refreshButtonOptions = { 
        idColumnSelector: 'workorderId:name',
        tableContainerSelector: '#wa-workorder-plan-search-result-container',
        refreshActionUrl: $('#wa-search-plan-workorder-url').attr('href'),
        loaderTargetSelector: 'div.wa-workorder-plan-page'
    };

    _wa_datatables_.addRefreshButton($table, $dataTable, refreshButtonOptions);

    if (_wa_plan_.map !== null)
        _wa_plan_.initMap();
    
});

_wa_plan_.selectAllFeatures = function (workorderIds) {
    let ids = [];

    workorderIds.forEach(function (workorderId) { ids.push('#awrid' + workorderId) });
    let $dataTable = $('#wa-workorder-plan-search-result-table').DataTable();
    $dataTable.rows(ids).select();
}

_wa_plan_.onMapSelection = function (selectEvent) {
    let $dataTable = $('#wa-workorder-plan-search-result-table').DataTable();

    if (selectEvent.selected.length > 0) {
        let rowIds = [];
        selectEvent.selected.forEach(function (feature) { rowIds.push('#awrid' + feature.getId()) });
        $dataTable.rows(rowIds).select();
    }

    if (selectEvent.deselected.length > 0) {
        let rowIds = [];
        selectEvent.deselected.forEach(function (feature) { rowIds.push('#awrid' + feature.getId()) });
        $dataTable.rows(rowIds).deselect();
    }
}

_wa_plan_.initMap = function(){
    let $table = $('#wa-workorder-plan-search-result-table');

    if (_wa_plan_.map === null) {
        _wa_plan_.map = new Map({ elementId: 'plan-map' });    
    } else {
        _wa_plan_.map.removeAllFeatures();
    }

    if ($('#plan-map canvas').length === 0) { // TODO: Add a method to map for checking if displayed instead
        _wa_plan_.map.init(_wa_plan_);
    }

    if ($table.length === 0)
        return;

    var $dataTable = $table.DataTable();

    var columnData = $dataTable.columns(['workorderId:name', 'applicationXcoordinate:name', 'applicationYcoordinate:name', "isAssigned:name"]).data();
    _wa_plan_.map.addFeatures(columnData[0], columnData[1], columnData[2], columnData[3]);

    var selectedRows = $dataTable.rows({ selected: true });
    if (selectedRows.count() > 0) {
        var workorderIdColumnIndex = $dataTable.column('workorderId:name').index();
        var selectedRowIndexes = selectedRows.indexes().toArray();
        var data = $dataTable.data();

        selectedRowIndexes.forEach(function (rowIndex) {
            var workorderId = data[rowIndex][workorderIdColumnIndex];
            _wa_plan_.map.selectFeatureById(workorderId);
        });
    }

    _wa_plan_.map.zoomToExtent();


    // Update map points on Table Select Event

    $dataTable.on('select.dt', function (e, dt, type, indexes) {

        if (type === 'row') {
            var data = $dataTable.cells(indexes, 'workorderId:name').data();
            data.toArray().forEach(_wa_plan_.map.selectFeatureById, _wa_plan_.map);
        }
    });

    // Update map points on Table Deselect Event
    $dataTable.on('deselect.dt', function (e, dt, type, indexes) {
        if (type === 'row') {
            var data = $dataTable.cells(indexes, 'workorderId:name').data();
            data.toArray().forEach(_wa_plan_.map.deselectFeatureById, _wa_plan_.map);
        }
    });
    
}

$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
    if (!$(e.target).is('#wa-plan-workorder-map-tab'))
        return;

    _wa_plan_.initMap();
});




export default _wa_plan_;
