﻿import ol from 'ol';
import ol_Map from 'ol/map';
import ol_View from 'ol/view';
import ol_Object from 'ol/object';
import ol_layer_Tile from 'ol/layer/tile';
import ol_source_BingMaps from 'ol/source/bingmaps';
import ol_control from 'ol/control';
import ol_control_Control from 'ol/control/control';
import ol_control_FullScreen from 'ol/control/fullscreen';
import ol_style_Style from 'ol/style/style';
import ol_style_Circle from 'ol/style/circle';
import ol_style_Fill from 'ol/style/fill';
import ol_style_Stroke from 'ol/style/stroke';
import ol_source_Vector from 'ol/source/vector';
import ol_layer_Vector from 'ol/layer/vector';
import ol_interaction from 'ol/interaction';
import ol_interaction_Select from 'ol/interaction/select';
import ol_interaction_DragBox from 'ol/interaction/dragbox';
import ol_events_condition from 'ol/events/condition';
import ol_format_GeoJSON from 'ol/format/geojson'

// TODO: Rename to _wa_Map_ and add function constructor
var _wa_Map_ = function (opt_options) {
    this.currentMapStyleIndex = 0;
    this.tileLayers = [];
    this.view = new ol_View({ center: [1327354.08, 7898543.28], zoom: 16 });
    this.selectInteraction = null;
    this.pointsLayer = null;
    this.styles = {};
    this.map = {};
    this.elementId = opt_options.elementId || 'map';
}; 

ol.inherits(_wa_Map_, ol_Object);

// TODO: Move api key to html body data attribute

_wa_Map_.prototype.setupBingMapType = function () {
    for (var currentLayer = 0, styleNames = ['Road', 'AerialWithLabels']; currentLayer < styleNames.length; ++currentLayer) {
        this.tileLayers.push(new ol_layer_Tile({
            visible: false,
            preload: Infinity,
            source: new ol_source_BingMaps({
                key: 'AtPXMOKt_oxtKi8meIstG2Ow0y0Zu7Po_87IoA_cC127uKm-2CtOLj-mYGJ55SBs',
                imagerySet: styleNames[currentLayer],
                maxZoom: 19  // 19 causes stretched tiles instead of no photos when zoomed the BingMaps
            })
        }));
    }

    this.map = new ol_Map({
        layers: this.tileLayers,
        // Improve user experience by loading tiles while dragging/zooming. Will make
        // zooming choppy on mobile or slow devices.
        interactions: ol_interaction.defaults({ doubleClickZoom: false }),
        loadTilesWhileInteracting: true,
        target: this.elementId,
        view: this.view,
        controls: ol_control.defaults().extend([
            new ol_control_FullScreen(),
            new _wa_Map_.ZoomToAllPointsControl({wa_Map_Instance: this}),
            new _wa_Map_.CycleTileLayerControl({ wa_Map_Instance: this })
        ])
    });

    this.changeMapStyle(this.currentMapStyleIndex);
};

_wa_Map_.prototype.changeMapStyle = function (styleIndex) {
    for (var currentLayer = 0; currentLayer < this.tileLayers.length; ++currentLayer) {
        this.tileLayers[currentLayer].setVisible(currentLayer === styleIndex);
    }
};

_wa_Map_.prototype.initStyles = function () {
    var greenCircleNormal = new ol_style_Circle({
        radius: 6,
        fill: new ol_style_Fill({ color: 'rgba(0, 255, 0, 0.4)' }),
        stroke: new ol_style_Stroke({ color: 'rgba(0, 100, 0, 0.8)', width: 2 })
    });

    var greenCircleSmall = new ol_style_Circle({
        radius: 4,
        fill: new ol_style_Fill({ color: 'rgba(0, 255, 0, 0.4)' }),
        stroke: new ol_style_Stroke({ color: 'rgba(0, 100, 0, 0.8)', width: 2 })
    });

    var greenCircleTiny = new ol_style_Circle({
        radius: 2,
        fill: new ol_style_Fill({ color: 'rgba(0, 255, 0, 0.4)' }),
        stroke: new ol_style_Stroke({ color: 'rgba(0, 100, 0, 0.8)', width: 2 })
    });

    var redCircleNormal = new ol_style_Circle({
        radius: 6,
        fill: new ol_style_Fill({ color: 'rgba(255, 0, 0, 0.4)' }),
        stroke: new ol_style_Stroke({ color: 'rgba(100, 0, 0, 0.8)', width: 2 })
    });

    var redCircleSmall = new ol_style_Circle({
        radius: 4,
        fill: new ol_style_Fill({ color: 'rgba(255, 0, 0, 0.4)' }),
        stroke: new ol_style_Stroke({ color: 'rgba(100, 0, 0, 0.8)', width: 2 })
    });

    var redCircleTiny = new ol_style_Circle({
        radius: 2,
        fill: new ol_style_Fill({ color: 'rgba(255, 0, 0, 0.4)' }),
        stroke: new ol_style_Stroke({ color: 'rgba(100, 0, 0, 0.8)', width: 2 })
    });

    this.styles['GreenCircleNormal'] = [new ol_style_Style({ image: greenCircleNormal })];
    this.styles['GreenCircleSmall'] = [new ol_style_Style({ image: greenCircleSmall })];
    this.styles['GreenCircleTiny'] = [new ol_style_Style({ image: greenCircleTiny })];
    this.styles['RedCircleNormal'] = [new ol_style_Style({ image: redCircleNormal })];
    this.styles['RedCircleSmall'] = [new ol_style_Style({ image: redCircleSmall })];
    this.styles['RedCircleTiny'] = [new ol_style_Style({ image: redCircleTiny })];
};

_wa_Map_.prototype.styleFunction = function (feature, resolution) {
    var primaryData = feature.get('primarydata') === 'true';

    if (this.view.getZoom() < 5) {
        return primaryData === true ? this.styles['RedCircleTiny'] : this.styles['GreenCircleTiny'];
    } else if (this.view.getZoom() < 8) {
        return primaryData === true ? this.styles['RedCircleSmall'] : this.styles['GreenCircleSmall'];
    } else {
        return primaryData === true ? this.styles['RedCircleNormal'] : this.styles['GreenCircleNormal'];
    }

};

/* Takes arrays of values, note that the coordinates must be string values, use .toString() where applicable */
_wa_Map_.prototype.addFeatures = function(ids, xcoordinates, ycoordinates, primarydata){

    var geoJson = {
        type: 'FeatureCollection',
        crs: {
            'type': 'name',
            'properties': { 'name': 'EPSG:3857' }
        }
    };

    let features = [];

    for (var i = 0; i < ids.length; i++) {
        let point = [Number(xcoordinates[i].replace(',', '.')), Number(ycoordinates[i].replace(',', '.'))]
        if (point[0] <= 0 || point[1] <= 0)
            continue;
        var feature = {
            id: ids[i],
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: point
            },
            properties: {
                primarydata: primarydata[i]
            }
        };

        features.push(feature);
    }

    geoJson.features = features;


    this.pointsLayer.getSource().addFeatures((new ol_format_GeoJSON()).readFeatures(geoJson));
    //, {
                    //dataProjection: 'EPSG:3857',
                    //featureProjection: 'EPSG:3857'
                //})
    
};

_wa_Map_.flickrStyle = function(feature) {
    var style = new ol_style_Style({
        image: new ol_style_Circle({
            radius: 6,
            stroke: new ol_style_Stroke({
                color: 'white',
                width: 2
            }),
            fill: new ol_style_Fill({
                color: 'green'
            })
        })
    });
    return [style];
}

_wa_Map_.prototype.init = function (eventHandlerFunctionsProvider) {
    this.setupBingMapType();

    this.initStyles();
    
    //if (initialGeometry !== undefined) {
        var geojsonVector = new ol_source_Vector({
            features: {}
        });
    //} else {
     //   var geojsonVector = new ol_source_Vector({});
    //}
        
    this.pointsLayer = new ol_layer_Vector({
        source: geojsonVector,
        style: this.styleFunction.bind(this)
    });
    
    this.selectInteraction = new ol_interaction_Select({
        condition: ol_events_condition.click
    });
    
    //this.selectInteraction.getFeatures().on("add", eventHandlerFunctionsProvider.onMapFeatureSelected);
    //this.selectInteraction.getFeatures().on("remove", eventHandlerFunctionsProvider.onMapFeatureDeselected);
    this.selectInteraction.on('select', eventHandlerFunctionsProvider.onMapSelection);

    var dragBox = new ol_interaction_DragBox({
        condition: ol_events_condition.shiftKeyOnly,
        style: new ol_style_Style({
            stroke: new ol_style_Stroke({
                color: [0, 0, 255, 1]
            })
        })
    });

    var wa_Map_Instance = this;

    dragBox.on('boxend', function (e) {
        var extent = dragBox.getGeometry().getExtent();
        var selectedIds = [];

        wa_Map_Instance.isSelectingFromTrue = false;
        wa_Map_Instance.pointsLayer.getSource().forEachFeatureIntersectingExtent(extent, function (feature) {
            selectedIds.push(feature.getId());
        });

        eventHandlerFunctionsProvider.selectAllFeatures(selectedIds);

    });

    this.map.addInteraction(dragBox);
    this.map.addInteraction(this.selectInteraction);

    this.map.addLayer(this.pointsLayer);
  
    this.map.updateSize();
    this.map.render();
};

_wa_Map_.prototype.zoomToExtent = function () {
    var extent = this.pointsLayer.getSource().getExtent();

    if (this.pointsLayer.getSource().getFeatures().length === 1) {
        var scaleFactor = 500;
        extent[0] -= scaleFactor;
        extent[2] += scaleFactor;
        extent[1] -= scaleFactor;
        extent[3] += scaleFactor;
    }
    this.map.getView().fit(extent, this.map.getSize());

};

_wa_Map_.prototype.removeAllFeatures = function () {
    this.selectInteraction.getFeatures().clear();
    this.pointsLayer.getSource().clear();
}

_wa_Map_.prototype.deselectFeatureById = function (id) {
    var feature = this.pointsLayer.getSource().getFeatureById(id);
    
    if (feature === null) {
        return;
    }

    // Prevent triggering remove event if not in array
    if (this.selectInteraction.getFeatures().getArray().indexOf(feature) !== -1) {
        this.selectInteraction.getFeatures().remove(feature);
    }
}

_wa_Map_.prototype.selectFeatureById = function (id) {
    let feature = this.pointsLayer.getSource().getFeatureById(id);

        if (feature === null) {
            return;
        }

        // Prevent adding again and triggering another added event if already added
        if (this.selectInteraction.getFeatures().getArray().indexOf(feature) === -1) {
            this.selectInteraction.getFeatures().push(feature);
        }
}

_wa_Map_.ZoomToAllPointsControl = function (opt_options) {
    var options = opt_options || {};
    var wa_Map_Instance = opt_options.wa_Map_Instance;
    var button = document.createElement('button');
    button.setAttribute('type', 'button'); // Prevent form submit
    button.innerHTML = 'A';

    //var this_ = this;
  

    var handleZoomAllPoints = function (e) {
        var extent = wa_Map_Instance.pointsLayer.getSource().getExtent();
        wa_Map_Instance.map.getView().fit(extent, wa_Map_Instance.map.getSize());
    };

    button.addEventListener('click', handleZoomAllPoints, false);
    button.addEventListener('touchstart', handleZoomAllPoints, false);

    button.setAttribute('data-toggle', 'tooltip');
    button.setAttribute('title', 'Visa alla punkter');

    var element = document.createElement('div');

    element.className = 'map-zoom-all-points ol-unselectable ol-control'; // CSS koder
    element.appendChild(button);

    ol_control_Control.call(this, {
        element: element//,
      //  target: options.target
    });
};

ol.inherits(_wa_Map_.ZoomToAllPointsControl, ol_control_Control);

_wa_Map_.CycleTileLayerControl = function (opt_options) {
    var options = opt_options || {};
    var wa_Map_Instance = opt_options.wa_Map_Instance;
    var button = document.createElement('button');
    button.setAttribute('type', 'button'); // Prevent form submit
    button.innerHTML = 'T';

    var handleCycleNextTileLayer = function (e) {
        wa_Map_Instance.currentMapStyleIndex++;
        if (wa_Map_Instance.currentMapStyleIndex >= wa_Map_Instance.tileLayers.length) {
            wa_Map_Instance.currentMapStyleIndex = 0;
        }
        wa_Map_Instance.changeMapStyle(wa_Map_Instance.currentMapStyleIndex);
    };

    button.addEventListener('click', handleCycleNextTileLayer, false);
    button.addEventListener('touchstart', handleCycleNextTileLayer, false);
    button.setAttribute('data-toggle', 'tooltip');
    button.setAttribute('title', 'Byt typ av karta');

    var element = document.createElement('div');

    element.className = 'map-cycle-next-tilelayer ol-unselectable ol-control'; // CSS koder
    element.appendChild(button);

    ol_control_Control.call(this, {
        element: element//,
       // target: options.target
    });
};

ol.inherits(_wa_Map_.CycleTileLayerControl, ol_control_Control);

export default _wa_Map_;
