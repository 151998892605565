﻿import _wa_datatables_ from "./webapp.datatables";
import _wa_toast_ from "./webapp.toast";

var _wa_project_ = {

}

$("#wa-main-content-container").on('init.dt', "#wa-project-search-result-container", function (e) {
    var $table = $(e.target);
    var $dataTable = $table.DataTable();

    //_wa_project_.addEditButton($table, $dataTable);
    var editButtonOptions = {
        url: $('#wa-project-editor-dialog-url').attr('href'),
        loaderTargetSelector: 'div.wa-project-page',
        idColumnSelector: 'projectId:name',
        contentInstruction: { replaceInnerFor: '#wa-project-search-result-container' },
        readonly: $('#wa-has-manage-projects-authorization').length === 0
    };
    _wa_datatables_.addEditButton($table, $dataTable, editButtonOptions);

    /*
    var refreshButtonOptions = {
        idColumnSelector: 'workorderId:name',
        tableContainerSelector: '#wa-workorder-assign-search-result-container',
        refreshActionUrl: $('#wa-search-assign-workorder-url').attr('href'),
        loaderTargetSelector: 'div.wa-workorder-assign-page'
    };

    _wa_datatables_.addRefreshButton($table, $dataTable, refreshButtonOptions);
    */
});
_wa_project_.addEditProjectDialogHandlers = function () {

    $("#wa-main-content-container").on('change', '.wa-edit-project-meter-manufacturer-dropdown', onChangeMeterManufacturer);
    $("#wa-main-content-container").on('change', '.wa-edit-project-thermometer-manufacturer-dropdown', onChangeThermometerManufacturer);
    function onChangeMeterManufacturer(e) {
        var $manufacturerId = $(this).val();
        var $parentForm = $(e.target).closest('form');
        updateMeterModelList($parentForm, $manufacturerId);
    }
    function onChangeThermometerManufacturer(e) {
        var $manufacturerId = $(this).val();
        var $parentForm = $(e.target).closest('form');
        updateThermometerModelList($parentForm, $manufacturerId);
    }
    function updateMeterModelList($parentForm, manufacturerId) {
        var $manufacturerSelect = $parentForm.find(".wa-edit-project-meter-manufacturer-dropdown");
        var $modelSelect = $parentForm.find(".wa-edit-project-meter-model-dropdown");
        var containerId = "DefaultMeterModel";
        var url = $manufacturerSelect.data("url");

        var contentPage = "wa-content-page.wa-project-page";

        var data = {
            ManufacturerId: manufacturerId,
            ContentPage: contentPage,
            ContainerId: containerId,
        };
        var options = {
            url: url,
            data: $.param(data),
            loaderTarget: contentPage,
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);
    }
    function updateThermometerModelList($parentForm, manufacturerId) {
        var $manufacturerSelect = $parentForm.find(".wa-edit-project-thermometer-manufacturer-dropdown");
        var $modelSelect = $parentForm.find(".wa-edit-project-thermometer-model-dropdown");
        var containerId = "DefaultThermometerModel";
        var url = $manufacturerSelect.data("url");

        var contentPage = "wa-content-page.wa-project-page";

        var data = {
            ManufacturerId: manufacturerId,
            ContentPage: contentPage,
            ContainerId: containerId,
        };
        var options = {
            url: url,
            data: $.param(data),
            loaderTarget: contentPage,
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);
    }
}




//$(".wa-project-checklist-table", this).on('init.dt', function (e) {


//$('.wa-project-dialog-action-button', this).on('click', function (event) {
    $("#wa-main-content-container").on('click', '.wa-project-dialog-action-button', function (event) {
    event.preventDefault();

    var $form = $(event.target).closest('form');
    if (!$form.validate().form()) {
        event.preventDefault();
        return;
    }
    var data = $form.serializeArray();

    var $dataTable = $form.find('.wa-project-checklist-table').DataTable({ "bRetrieve": true });
    var rowIndexes = $dataTable.rows().indexes();
    var $Ids = $dataTable.cells(rowIndexes, 'Id:name').data().toArray();
    var $Texts = $dataTable.cells(rowIndexes, 'Text:name').data().toArray();
    var $SortOrders = $dataTable.cells(rowIndexes, 'SortOrder:name').data().toArray();

    if ($(event.currentTarget).data('content-page') != null)
        data.push({ name: 'contentPage', value: $(event.currentTarget).data('content-page') });
    //            data = $.extend({}, data, test);

    $.each($Ids, function (idx, val) {
        data.push({
            name: 'ProjectCheckList.Rows[' + idx + '].Id', value: val
        });
    });
    $.each($Texts, function (idx, val) {
        data.push({
            name: 'ProjectCheckList.Rows[' + idx + '].Text', value: val
        });
    });

        $.each($SortOrders, function (idx, val) {
        data.push({
            name: 'ProjectCheckList.Rows[' + idx + '].SortOrder', value: val
        });
    });

    //  data = $.extend({}, data, test);
    var loaderMessage = $(event.currentTarget).data('loader-message');

    var options = {
        url: $form.attr('action'),
        data: $.param(data),
        loaderTarget: $('div.wa-content-page'), // TODO Find the closest dialog instead
        loaderMessage: loaderMessage,
        afterSuccess: function () {
            $form.closest('.ui-dialog-content').dialog('close');
        }
    };
    $.fn.postAjax(options);
});





_wa_project_.addEditProjectDialogHandlers();
