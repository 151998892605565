﻿import _wa_datatables_ from "./webapp.datatables";
import _wa_toast_ from "./webapp.toast";

var _wa_typedata_ = {

}

$("#wa-main-content-container").on('init.dt', "#wa-type-data-search-result-container", function (e) {
    var $table = $(e.target);
    var $dataTable = $table.DataTable();
    var $data = {};
    var $ModelDataTypeId = $table.closest("#wa-type-data-search-result-container").find(".ModelDataTypeId").val();
    $data.ModelDataTypeId = $ModelDataTypeId;
    var editButtonOptions = {
        url: $('#wa-type-data-editor-dialog-url').attr('href'),
        loaderTargetSelector: 'div.wa-type-data-page',
        idColumnSelector: 'typeDataId:name',
        contentInstruction: { replaceInnerFor: '#wa-type-data-search-result-container' },
        readonly: $('#wa-has-manage-type-data-authorization').length === 0,
        data: $data
    };
    var deleteButtonOptions = {
        url: $('#wa-type-data-delete-dialog-url').attr('href'),
        loaderTargetSelector: 'div.wa-type-data-page',
        idColumnSelector: 'typeDataId:name',
        contentInstruction: { replaceInnerFor: '#wa-type-data-search-result-container' },
        readonly: $('#wa-has-manage-type-data-authorization').length === 0,
        data: $data
    };

    _wa_datatables_.addEditButton($table, $dataTable, editButtonOptions);
    _wa_datatables_.addDeleteButton($table, $dataTable, deleteButtonOptions);



    /*
    var refreshButtonOptions = {
        idColumnSelector: 'workorderId:name',
        tableContainerSelector: '#wa-workorder-assign-search-result-container',
        refreshActionUrl: $('#wa-search-assign-workorder-url').attr('href'),
        loaderTargetSelector: 'div.wa-workorder-assign-page'
    };

    _wa_datatables_.addRefreshButton($table, $dataTable, refreshButtonOptions);
    */
});
_wa_typedata_.addMeterClassChangeHandlers = function () {
    $("#wa-main-content-container").on('change', '.wa-typedata-meterclass-dropdown', onChangeMeterClass);
    function onChangeMeterClass(e) {

        var $classId = $(this).val();
        var $url = $(this).data("url");
        updateClassList($url, $classId);
    }
    function updateClassList($url, $classId) {
        //toggleModelMissing($parentForm, true);
        var contentPage = 'wa-type-data-management-page';
        //var containerId = 'wa-typedata-meter-normtype-container';
        var data = { MeterClassTypeId: $classId, ContentPage: contentPage };
        var options = {
            url: $url,
            data: $.param(data),
            loaderTarget: contentPage,
            loaderMessage: "Laddar dialog..."

        };
        $.fn.postAjax(options);

    }
}

$("#wa-main-content-container").on('change', '.wa-typedata-modeldatatypes-dropdown', function (e) {
    var $modelDataTypesDropDown = $(e.currentTarget);

    var modelDataTypeId = +$modelDataTypesDropDown.val();
    //var appa = $('#ModelDataTypesEnum').data('manufacturer');
    //var appa2 = $('#ModelDataTypesEnum').data('meter');
    var isManufacturer = modelDataTypeId === $('#ModelDataTypesEnum').data('manufacturer');
    var isRegenerateReasonType = modelDataTypeId === $('#ModelDataTypesEnum').data('regenerate-reason');
    var isRegenerateReasonGroupType = modelDataTypeId === $('#ModelDataTypesEnum').data('regenerate-reason-group');
    var isMeter = modelDataTypeId === $('#ModelDataTypesEnum').data('meter');
    var isThermometer = modelDataTypeId === $('#ModelDataTypesEnum').data('thermometer');
    var isFailReasonType = modelDataTypeId === $('#ModelDataTypesEnum').data('fail-reason-type');
    var isVoltagePrimary = modelDataTypeId === $('#ModelDataTypesEnum').data('voltage-primary-type');
    var isVoltageSecondary = modelDataTypeId === $('#ModelDataTypesEnum').data('voltage-secondary-type');
    var isCurrentPrimary = modelDataTypeId === $('#ModelDataTypesEnum').data('current-primary-type');
    var isCurrentSecondary = modelDataTypeId === $('#ModelDataTypesEnum').data('current-secondary-type');
    var isCableType = modelDataTypeId === $('#ModelDataTypesEnum').data('cable-type');
    var isCableAreaType = modelDataTypeId === $('#ModelDataTypesEnum').data('cable-area-type');

    var $form = $modelDataTypesDropDown.closest('form');

    if (isVoltagePrimary || isCurrentPrimary) {
        $form.find('.wa-typedata-primary-container').visible();
        $form.find('.wa-typedata-primary-container').removeAttr("height");
        //$form.find('.wa-typedata-primary-container').removeAttr("maxWidth");
    }
    else {
        $form.find('.wa-typedata-primary-container').invisible();
        $form.find('.wa-typedata-primary-container').attr("height", "0");
        //$form.find('.wa-typedata-primary-container').attr("maxWidth","0");

    }
    if (isVoltageSecondary || isCurrentSecondary) {
        $form.find('.wa-typedata-secondary-container').visible();
        $form.find('.wa-typedata-secondary-container').removeAttr("height");
        //$form.find('.wa-typedata-secondary-container').removeAttr("maxWidth");
    }
    else {
        $form.find('.wa-typedata-secondary-container').invisible();
        $form.find('.wa-typedata-secondary-container').attr("height", "0");
        //$form.find('.wa-typedata-secondary-container').attr("maxWidth", "0");
    }
    if (isCableAreaType) {
        $form.find('.wa-typedata-cablearea-container').visible();
        $form.find('.wa-typedata-cablearea-container').removeAttr("height");
        //$form.find('.wa-typedata-secondary-container').removeAttr("maxWidth");
    }
    else {
        $form.find('.wa-typedata-cablearea-container').invisible();
        $form.find('.wa-typedata-cablearea-container').attr("height", "0");
        //$form.find('.wa-typedata-secondary-container').attr("maxWidth", "0");
    }

    

    if (isMeter) {
        $form.find('.wa-typedata-meter-container').visible();
        $form.find('.wa-typedata-meter-container').removeAttr("height");
        //$form.find('.wa-typedata-meter-container').removeAttr("maxWidth");

    }
    else {
        $form.find('.wa-typedata-meter-container').invisible();
        $form.find('.wa-typedata-meter-container').attr("height", "0");
        // $form.find('.wa-typedata-meter-container').attr("maxWidth", "0");
    }
    if (isThermometer) {
        $form.find('.wa-typedata-thermometer-container').visible();
        $form.find('.wa-typedata-thermometer-container').removeAttr("height");
        //$form.find('.wa-typedata-meter-container').removeAttr("maxWidth");
    }
    else {
        $form.find('.wa-typedata-thermometer-container').invisible();
        $form.find('.wa-typedata-thermometer-container').attr("height", "0");
        // $form.find('.wa-typedata-meter-container').attr("maxWidth", "0");
    }

    if (isManufacturer || isRegenerateReasonGroupType || isFailReasonType || isVoltagePrimary || isVoltageSecondary || isCurrentPrimary || isCurrentSecondary || isCableType || isCableAreaType) {
        $form.find('.wa-typedata-manufacturer-container').addClass('d-none');
        $form.find('.wa-typedata-reasongroup-container').addClass('d-none');
    }
    else {
        if (isRegenerateReasonType) {
            $form.find('.wa-typedata-reasongroup-container').removeClass('d-none');
            $form.find('.wa-typedata-manufacturer-container').addClass('d-none');
        }
        else {
            $form.find('.wa-typedata-manufacturer-container').removeClass('d-none');
            $form.find('.wa-typedata-reasongroup-container').addClass('d-none');
        }
    }




});

$("#wa-main-content-container").on('change', '.wa-search-typedata-modeldatatype-dropdown', function (e) {
    var $modelDataTypesDropDown = $(e.currentTarget);

    var modelDataTypeId = +$modelDataTypesDropDown.val();
    var $form = $modelDataTypesDropDown.closest('form');
    var url = $modelDataTypesDropDown.data("url");

    var contentPage = 'wa-type-data-management-page';
    var containerId = 'wa-type-data-search-manufacturer-list';

    var data = {
        ModelDataTypeId: modelDataTypeId,
        ContentPage: contentPage,
        ContainerId: containerId
    };
    var options = {
        url: url,
        data: $.param(data),
        loaderTarget: contentPage,
        loaderMessage: "Laddar dialog..."

    };
    $.fn.postAjax(options);

    var isManufacturer = modelDataTypeId === $('#ModelDataTypesEnum').data('manufacturer');
    var isRegenerateReasonType = modelDataTypeId === $('#ModelDataTypesEnum').data('regenerate-reason');
    var isRegenerateReasonGroupType = modelDataTypeId === $('#ModelDataTypesEnum').data('regenerate-reason-group');
    var isFailReasonType = modelDataTypeId === $('#ModelDataTypesEnum').data('fail-reason-type');
    var isVoltagePrimary = modelDataTypeId === $('#ModelDataTypesEnum').data('voltage-primary-type');
    var isVoltageSecondary = modelDataTypeId === $('#ModelDataTypesEnum').data('voltage-secondary-type');
    var isCurrentPrimary = modelDataTypeId === $('#ModelDataTypesEnum').data('current-primary-type');
    var isCurrentSecondary = modelDataTypeId === $('#ModelDataTypesEnum').data('current-secondary-type');
    var isCableType = modelDataTypeId === $('#ModelDataTypesEnum').data('cable-type');
    var isCableAreaType = modelDataTypeId === $('#ModelDataTypesEnum').data('cable-area-type');

    if (isManufacturer || isRegenerateReasonGroupType || isFailReasonType || isVoltagePrimary || isVoltageSecondary || isCurrentPrimary || isCurrentSecondary || isCableType || isCableAreaType) {
        $form.find('.wa-type-data-search-manufacturer-list').addClass('d-none');
        $form.find('.wa-type-data-search-reasongrouptype-list').addClass('d-none');
    }
    else {
        if (isRegenerateReasonType) {
            $form.find('.wa-type-data-search-manufacturer-list').addClass('d-none');
            $form.find('.wa-type-data-search-reasongrouptype-list').removeClass('d-none');
        }
        else {
            $form.find('.wa-type-data-search-manufacturer-list').removeClass('d-none');
            $form.find('.wa-type-data-search-reasongrouptype-list').addClass('d-none');
        }
    }




});

$(document).on('click', 'button.wa-perform-delete-typedata-button', function (event) {
    var $form = $(event.currentTarget).closest('form.wa-delete-typedata-form');
    var data = $form.serializeArray();

    var $deleteTypeDataTable = $form.find('#wa-typedata-search-result-table').DataTable();
    var selectedIds = $deleteTypeDataTable.column('typeDataId:name').data().toArray();

    selectedIds.forEach(function (typeDataId, i) {
        data.push({ name: "selectedIds[" + i + "]", value: typeDataId })
    });

    var options = {
        url: $form.attr('action'),
        data: $.param(data),
        loaderTarget: $('div.wa-type-data-management-page'), // TODO Find the closest dialog instead
        loaderMessage: "Tar bort typdata...",
        afterSuccess: function () {
            $form.closest('.ui-dialog-content').dialog('close');
        }
    };
    $.fn.postAjax(options);



});
_wa_typedata_.addMeterClassChangeHandlers();